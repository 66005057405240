import React from "react";
import { Modal } from 'antd';
import IconFont from '@/common/js/iconfonts/'
import './index.less';

interface IProps {
    children: any
    [key:string]: any
}

const Dialog = (props: IProps) => {
    const { children, ...configs } = props
    return <Modal 
    {...configs} 
    closeIcon={<IconFont type={'icon-Close1'} />}
    wrapClassName={props.class}>
        { children }
    </Modal>
};

export default Dialog;