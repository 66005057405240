import zhCN from "antd/lib/locale/zh_CN";
import ptBR from "antd/lib/locale/pt_BR";
import esES from "antd/lib/locale/es_ES";
import enUS from "antd/lib/locale/en_US";

let langObj = {
    'zh-CN': {
        locale: 'zh',
        antdLocale: zhCN
    },
    'pt-BR': {
        locale: 'br',
        antdLocale: ptBR
    },
    'es-MX': {
        locale: 'mx',
        antdLocale: esES
    },
    'en-US': {
        locale: 'en',
        antdLocale: enUS
    },
}

export const getAntdLocale = (language: string) => {
    const antdLocale = langObj[language].antdLocale;
    return antdLocale
}

