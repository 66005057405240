module.exports = {
  "GRider_part1_Enter_NzrE": "请输入",
  "GRider_part1_Available_surplus_qJnT": "可用余额",
  "Fintech_Payment_activation_IzyA_cilV": "第{{num}}步",
  "Fintech_Payment_homepage__XSnB": "可以查看交易数据及交易量趋势",
  "GRider_part1_Daily_newspaper_LEkt": "日报",
  "Fintech_Payment_installment_YXNp_PFht": "想要开通分期付款，你需要准备好过往交易的数据，然后联系我们的BD或是发邮件至{{email_address}}",
  "GRider_part2_Beneficiaries_ofYu": "受益人",
  "GRider_part1_Refusal_to_pkkj": "拒付报表",
  "Fintech_Payment_balance_GMTI_Quqq": "期末余额=期初余额+（交易金额-费用-提现）",
  "GRider_part2_Tip_The_SSvk": "提示：邮箱和手机号在用户创建后将无法修改",
  "GRider_part1_Card_issuing_UMOj": "发卡行",
  "Fintech_Payment_activation_Gwim_BefB": "在接入前，你需要先为服务商申请认证，请选择你的服务商",
  "GRider_part1_Yesterday_Qqwf": "昨日",
  "Fintech_Payment_activation_GjlD_wkpD": "自行唤起{{brand_name}}应用程序接口",
  "Fintech_Payment_installment_hTIQ_Vrgf": "账户",
  "Fintech_Payment_balance_eQKY_heQN": "开发者工具",
  "Fintech_Payment_installment_qiLa_XtZT": "操作成功",
  "GRider_part1_Today_YHmE": "今日",
  "GRider_testing_Payment_VpTU": "支付",
  "Fintech_Payment_homepage__RmdB": "报表服务",
  "Wallet_App_Platform_Freezing_ppNk": "冻结",
  "Fintech_Payment_installment_PFLR_ompu": "接受分期支付",
  "Fintech_Payment_activation_vUKr_wcKq": "{{platform_name}}的证书",
  "Fintech_Payment_installment_hSSg_zUNZ": "根据期数或月数的不同，会有相应的分期利息。",
  "Fintech_Payment_balance_elMb_vZFR": "数据解释",
  "Fintech_Payment_balance_KpPH_aCTM": "账户信息",
  "Fintech_Payment_balance_jhxV_SUuT": "快捷入口",
  "GRider_testing_Action_OCOw": "操作",
  "Fintech_Payment_homepage__NBZW": "你好！",
  "GRider_part1_Sent_successfully_kjRA": "发送成功",
  "GRider_part1_Description_wbwg": "描述",
  "GRider_part2_Download_WoXh": "下载",
  "GRider_part2_E_mail_jpRL": "电子邮箱",
  "GRider_part2_Associated_Billing_YMdm": "关联结算单号",
  "Fintech_Payment_activation_LhwA_UQij": "申请",
  "GRider_testing_The_test_mTcU": "当前正在使用测试账号，你可以修改每个场景中的规则来模拟测试结果。",
  "GRider_part1_Order_No_Ycoc": "收银订单号",
  "Fintech_Payment_activation_YFdK_GDwn": "获取更多信息？请点击这里",
  "GRider_part1_Total_balance_DpEi": "总余额",
  "Fintech_Payment_activation_DmlQ_MEHJ": "你似乎还没有完成对接流程",
  "GRider_part1_Statement_of_EvSW": "结算报表",
  "GRider_part1_Reset_Password_qUom": "重置密码",
  "Fintech_Payment_activation_Jgbl_pUBk": "提供支持",
  "GRider_part2_View_and_IngR": "查看并搜索",
  "GRider_testing_Rules_wFPn": "规则",
  "Fintech_Payment_activation_ewlq_IoNo": "验证码发送成功",
  "GRider_part2_Waiting_geZn": "等待中",
  "GRider_part2_Name_of_MryB": "银行名称",
  "GRider_part2_callback_address_ktAH": "回调地址",
  "Fintech_Payment_installment_KJiW_YtRu": "用户的订单金额大于该金额时，可展示对应分期数",
  "GRider_part1_Card_Expired_PDgL": "卡过期时间",
  "GRider_part2_Related_withdrawals_waNU": "相关提现",
  "Fintech_Payment_installment_rJDb_YgOl": "分期数",
  "GRider_part2_File_size_YxTo": "文件大小",
  "GRider_part2_Mid_ylNK": "mid",
  "GRider_part3_Successfully_replicated_waxb": "已成功复制",
  "GRider_part2_You_can_JwSD": "您可以配置回调地址以接收通知",
  "GRider_part3_Incorrect_mobile_nRlv": "手机号格式不正确",
  "Fintech_Payment_activation_jxCH_yKtk": "模拟测试",
  "GRider_part2_Option_MwIV": "已选择",
  "GRider_part2_Platform_Public_awvm": "平台公钥",
  "GRider_part1_Cards_kUwm": "发卡国",
  "Fintech_Payment_homepage__fqqN": "产品特点",
  "GRider_part2_Update_Time_gjBJ": "更新时间",
  "GRider_part2_Bank_Account_loWF": "银行账号",
  "Fintech_Payment_installment_ymsu_wzNC": "资金",
  "Fintech_Payment_activation_ihwN_LvcJ": "下一步",
  "Fintech_Payment_installment_nABX_fEaU": "与我们的BD联系并签署附加协议",
  "GRider_part2_You_can_hYnM": "您可以查询并获取生产密钥信息",
  "GRider_part1_Home_Page_uezP": "主页",
  "GRider_part2_Name_of_oyxC": "文件名称",
  "GRider_part2_Production_keys_NWqC": "生产密钥",
  "GRider_part2_Save_Limz": "保存",
  "Fintech_Payment_installment_llHq_rMAE": "主页",
  "GRider_part2_Appid_BsOA": "appid",
  "GRider_part2_Enter_a_zelI": "请输入名字",
  "GRider_part2_Create_a_jFJI": "创建用户",
  "Fintech_Payment_installment_hjTq_dlnR": "分期付款方案",
  "Fintech_Payment_balance_NbxK_SaKX": "可以查看结算银行账户信息等",
  "GRider_part2_Contains_all_XBsh": "包含每天创建的所有交易",
  "Fintech_Payment_installment_DVlZ_kYzm": "当你选择某个分期数时，代表选择了该分期数及以下的全部期数。例如，选择了6期，代表选择了2～6期。",
  "Fintech_Payment_activation_Ifcd_qoWL": "如果有服务商帮助你搭建系统，请选择该项",
  "Fintech_Payment_installment_UPAc_xQXH": "最高还款金额",
  "Fintech_Payment_installment_cGuN_hnbl": "报表",
  "GRider_part2_Reason_hMEF": "原因",
  "GRider_part1_Refund_amount_DAYO": "退款金额",
  "GRider_testing_Overdue_period_XwCh": "过期时间",
  "Fintech_Payment_activation_ozQV_GveB": "确认",
  "GRider_part2_Is_a_Dnrr": "是否确认发起退款？",
  "GRider_part2_Character_Details_FNou": "角色详情",
  "GRider_part1_Between_the_eAiK": "创建时间",
  "Fintech_Payment_installment_DnTv_Xkvt": "{{brand_name}}当前支持信用卡分期付款。该功能将帮助你提高订单转化率及交易量。",
  "Fintech_Payment_balance_hSpF_MbAB": "今日",
  "Fintech_Payment_balance_nyOS_vxXE": "点击进入对应模块完成设置或查看所需信息。",
  "Fintech_Payment_balance_CuLR_ULHc": "期末余额",
  "Fintech_Payment_installment_ZVzi_GWHv": "发卡行",
  "GRider_part2_Callback_address_mWOg": "回调地址配置",
  "GRider_part2_Store_Private_wkmJ": "商户私钥",
  "GRider_part1_We_will_UNRe": "我们将会把报告发送到以下邮箱",
  "GRider_part2_Cost_vEwI": "费用",
  "GRider_part2_Withdrawal_Details_dHNg": "提现详情",
  "Fintech_Payment_installment_Vaha_KRAt": "自行在商户平台上激活分期付款功能",
  "GRider_part1_The_past_mhPm": "过去一个月",
  "Fintech_Payment_balance_NFvu_eglf": "期初余额",
  "GRider_part1_Payment_Details_DAiw": "支付详情",
  "Fintech_Payment_installment_AVlb_xZKm": "{{specific_number}}个月",
  "GRider_part2_Refunded_asmQ": "已退票",
  "GRider_part1_Type_LsnR": "类型",
  "Fintech_Payment_activation_bMnh_ctqQ": "如果你自己搭建网站及相关系统，则选择该项",
  "Fintech_Payment_homepage__ypPH": "开发者",
  "GRider_part2_The_most_RGVh": "最近登陆时间",
  "GRider_part2_Click_the_LDVM": "点击右侧按钮发送到邮箱",
  "GRider_part1_Order_No_YaNe": "收银订单号/商户订单号",
  "Fintech_Payment_installment_gQRp_NZeC": "你无需进行任何更改，我们会完成所有的设置，包括分期付款方案及每期最低还款金额。",
  "GRider_part3_Refund_has_KQgL": "已成功发起退款",
  "Fintech_Payment_homepage__KZpP": "欢迎来到{{brand_name}}的商户平台，在这里你可以找到需要的日常运营所需的数据和功能。",
  "Fintech_Payment_balance_IpxB_ywzS": "包括四类交易，支付、退款、拒付和拒付返还的总金额；其中支付和拒付返还计为正、退款和拒付金额计为负。",
  "Fintech_Payment_activation_wjcD_GlZe": "订阅后，有因顾客交易纠纷产生的退款发生时，将会发送通知到你的邮箱",
  "GRider_part1_Amount_paid_WEun": "支付金额",
  "Fintech_Payment_installment_FgNU_zmkD": "三步开启分期支付功能",
  "Wallet_App_Platform_The_money_vDON": "该账户的钱是扣减手续费后的交易和退款的净额，将会在满足结算条件后，转移到现金账户里",
  "GRider_part1_Balance_to_QsRx": "待结算余额",
  "GRider_testing_Set_Rules_zJNI": "设置规则",
  "Fintech_Payment_activation_qLYh_PPxk": "选择你的服务商",
  "Fintech_Payment_installment_qogy_NcYo": "三步开启分期支付功能",
  "GRider_part3_URL_format_iCKD": "URL格式错误",
  "GRider_part2_Net_transactions_gAaN": "交易净额",
  "Fintech_Payment_balance_yezl_qhlK": "刷新",
  "Fintech_Payment_installment_IPpd_Svmd": "费用",
  "GRider_part1_Today_s_hdrF": "今日趋势",
  "Fintech_Payment_activation_nxJQ_NgNJ": "平台可用性及维护",
  "GRider_part2_Action_cmsL": "操作",
  "GRider_part1_Export_YZAn": "导出",
  "Fintech_Payment_installment_YUrU_qPmd": "大多数主要的发卡行都支持我们的功能，请查看你的发卡行是否在其中。",
  "Fintech_Payment_installment_riIt_DDvt": "不设限",
  "GRider_part2_Send_to_AtRh": "发送至邮箱",
  "Fintech_Payment_activation_dgcg_YmoP": "请选择服务商",
  "Fintech_Payment_installment_rOTK_eywP": "请选择你接受的分期付款方案",
  "GRider_testing_Simulation_test_vGQn": "模拟测试",
  "Fintech_Payment_activation_uczM_UXBr": "选择该模式",
  "GRider_part3_Successfully_saved_lalL": "已成功保存",
  "GRider_part3_Refund_failed_VnYA": "退款失败，请稍后再试",
  "GRider_testing_Scenarios_iISM": "场景",
  "GRider_part1_Confirmation_AyRV": "确认",
  "Fintech_Payment_balance_DtSD_XkwH": "按交易金额",
  "GRider_part2_Total_balance_YZYv": "总余额",
  "Fintech_Payment_installment_SQJZ_aEpL": "你可以在这里为顾客设置分期付款",
  "Fintech_Payment_installment_kMba_amRL": "最低还款金额",
  "GRider_part1_Refundable_sZhr": "可退金额",
  "GRider_part1_Amount_vtzS": "金额",
  "Fintech_Payment_activation_obwW_aWIM": "订阅全部",
  "Fintech_Payment_installment_mEXH_WggE": "角色管理",
  "Fintech_Payment_balance_GbVq_mdCb": "是指结算后的转账金额。如果提现失败，将会立刻返还该笔金额，此时金额计为负。",
  "Fintech_Payment_activation_kfLV_lJHI": "申请口令",
  "Fintech_Payment_installment_dddG_AtTw": "分期期数",
  "GRider_part2_Withdrawal_ID_SrUS": "提现ID",
  "GRider_part2_All_transactions_zvvZ": "包含每次结算的所有交易",
  "GRider_part2_Cash_Amount_LJKk": "提现金额",
  "GRider_testing_Select_the_zelH": "选择场景",
  "GRider_part2_Unable_to_yJGm": "删除后将无法恢复",
  "GRider_part2_Resumed_after_hqrX": "{{time}}秒后重新发",
  "GRider_part1_Clear_pScF": "清空",
  "GRider_part2_Delete_the_lFFi": "是否删除该用户？",
  "Fintech_Payment_balance_hfIW_kqDR": "提现",
  "Fintech_Payment_installment_YUyv_ddsJ": "余额支付",
  "Fintech_Payment_activation_wfOI_wbgn": "请输入验证码",
  "GRider_part2_Total_transaction_vOfv": "总交易金额",
  "GRider_testing_Test_account_jNcp": "测试账号",
  "GRider_part1_Cancel_VJqj": "取消",
  "GRider_part2_Activation_oBkK": "激活",
  "GRider_part1_Transaction_Details_QlYj": "交易详情",
  "Fintech_Payment_installment_iwhg_GXAN": "订阅",
  "Fintech_Payment_installment_mBYW_wReM": "分期支付",
  "Fintech_Payment_activation_FXqF_QMDQ": "间连",
  "Fintech_Payment_homepage__ckdF": "可提供详细的交易、结算及拒付报表",
  "GRider_part2_Role_kDjH": "角色",
  "GRider_part2_Copy_mepW": "复制",
  "GRider_part1_User_management_XlPK": "用户管理",
  "GRider_part1_Role_management_BGrU": "角色管理",
  "Fintech_Payment_activation_NHCc_pjPW": "订阅",
  "GRider_part3_The_key_BlJh": "密钥已发送至管理员的邮箱{{email}}，请查收",
  "GRider_testing_Key_and_pUWf": "密钥和回调",
  "Fintech_Payment_activation_WPSw_nvwn": "由服务商唤起{{brand_name}}应用程序接口",
  "GRider_part3_Account_Details_Rhrj": "账户详情",
  "Fintech_Payment_installment_nZUF_eajs": "你的顾客可以使用分期付款的方式支付",
  "GRider_part1_Status_QkjC": "状态",
  "GRider_part2_Delete_Mdzk": "删除",
  "Fintech_Payment_balance_OMHo_KuHt": "选择对比时间",
  "GRider_part3_Tip_Modify_ZuaO": "提示：保存后仍可修改",
  "GRider_part2_Did_you_zYqT": "是否删除该角色？",
  "GRider_part2_Create_a_cXhs": "创建一个新用户",
  "GRider_part1_Withdrawal_hMai": "提现",
  "GRider_part3_Contains_daily_mqQx": "包含了每天产生和更新的拒付数据",
  "Fintech_Payment_balance_JuIe_SCxH": "截止到{{specific_hour}}",
  "GRider_part1_The_past_KXKB": "过去一年",
  "Fintech_Payment_homepage__mrUl": "完善的开发者文档和模拟测试功能",
  "Fintech_Payment_activation_ZSUb_fBQj": "证书信息",
  "Fintech_Payment_activation_kngw_rjJO": "直连",
  "Fintech_Payment_installment_HVdy_lDaD": "交易",
  "Fintech_Payment_homepage__iUXv": "数据看板",
  "GRider_part3_Cancel_rVRs": "取消",
  "Fintech_Payment_activation_tNUu_MyKS": "该步骤用于保护你的账户信息安全，验证码已发送至{{email_address}}",
  "Fintech_Payment_activation_GNJM_joSQ": "自行将{{brand_name}}的参数配置到自己的系统中",
  "Fintech_Payment_onboarding_mhXP_NLLg": "格式错误",
  "GRider_testing_Test_account_nnib": "测试账号",
  "Wallet_App_Platform_This_account_TkJu": "该账户是处于风险筛查中的交易净额，请您查收邮件，并根据邮件提示进行申诉。",
  "GRider_part2_Select_permission_lHal": "请选择权限",
  "Fintech_Payment_balance_SqOx_AyAJ": "是指当日00:00（{{time_zone}})时的账户总余额，和前一日期末的余额相同。",
  "GRider_part2_Here_is_JDga": "这里展示的是您的结算银行账户信息，如果需要修改，请联系您的账号经理",
  "GRider_part2_Creating_a_zflX": "创建角色",
  "GRider_part2_Create_a_SGfq": "创建一个新角色",
  "Fintech_Payment_activation_zjqY_gLat": "请首先选择接入方式",
  "GRider_part1_Related_Transactions_UIBX": "相关交易",
  "GRider_part2_Name_vbyE": "名字",
  "Fintech_Payment_activation_LjzQ_NEsx": "退款通知",
  "Fintech_Payment_balance_tuMc_oNCn": "费用",
  "Fintech_Payment_balance_DbDe_VdbR": "可选择的时间长度不得超过左侧已选定的时间长度",
  "GRider_part2_Date_hYez": "日期",
  "GRider_part1_Accounts_BFvS": "账户",
  "Fintech_Payment_activation_JQpN_Tszu": "请确认",
  "Fintech_Payment_activation_Ddpw_LpmL": "申请密钥",
  "Wallet_App_Platform_Thawing_hqVt": "解冻",
  "GRider_testing_Successful_payment_Yanu": "支付成功",
  "Fintech_Payment_activation_HqZu_HXzt": "正式运行前需要在{{brand_name}}上进行模拟测试",
  "Fintech_Payment_activation_NqtQ_sjWJ": "直连",
  "Wallet_App_Platform_Funds_from_uGZs": "该账户的资金即将自动提现到您的银行账户中",
  "GRider_part2_Telephone_Number_vMII": "电话号码",
  "GRider_part2_Withdrawal_refund_zKZp": "提现退票",
  "GRider_part2_Enter_email_MOOA": "请输入邮箱",
  "GRider_part2_Edit_EsRp": "编辑",
  "Fintech_Payment_installment_bSZx_AgaB": "用户管理",
  "GRider_part1_Card_Type_QJgr": "卡类型",
  "Fintech_Payment_activation_tnxr_cHMD": "通过平台接入",
  "Fintech_Payment_activation_FHYl_spTb": "正式运行前需要在服务商平台上使用测试账号完成测试",
  "GRider_part1_Should_be_wIqf": "应用",
  "Fintech_Payment_installment_CqfD_Ivza": "费用",
  "GRider_part1_The_last_IpXv": "过去三个月",
  "Fintech_Payment_installment_heim_nBgM": "请设置每一期的最小可分期订单金额",
  "Wallet_App_Platform_Freeze_surplus_Woql": "冻结余额",
  "Fintech_Payment_activation_ZQbh_hGRy": "订阅后，所有关于平台可用性、平台服务中断及日常维护的信息会发送至你的邮箱",
  "Wallet_App_Platform_Freezing_lsVa": "冻结",
  "GRider_part1_Order_No_OZVW": "商户订单号",
  "GRider_part1_Developers_UQKx": "开发者",
  "GRider_part1_Transactions_KxSe": "交易",
  "Fintech_Payment_balance_mwQV_TfRo": "查看详情",
  "Fintech_Payment_installment_imFR_PvzU": "支持的发卡行",
  "GRider_testing_Change_vqmJ": "修改",
  "Fintech_Payment_installment_gveY_fVAe": "设置",
  "GRider_part1_Export_Transactions_RRhr": "导出交易报告",
  "Fintech_Payment_installment_KSvh_imKX": "支付方式",
  "GRider_part2_Enter_your_GLIm": "请输入电话号码",
  "Fintech_Payment_installment_RYcT_IbTU": "卡品牌",
  "Fintech_Payment_activation_xoUl_LHiq": "将{{brand_name}}的证书发送给你的服务商以完成接入流程",
  "GRider_part1_Card_number_fuXk": "卡号",
  "Fintech_Payment_activation_JIXy_NPez": "上一步",
  "Fintech_Payment_activation_isyA_IErj": "取消",
  "GRider_part2_Editing_User_zQpH": "编辑用户信息",
  "GRider_part2_Editing_role_Jtat": "编辑角色信息",
  "Fintech_Payment_installment_jEkP_xnAG": "分期支付设置",
  "Fintech_Payment_installment_RysT_ofxZ": "分期支付接入",
  "Fintech_Payment_balance_TDMh_lWOL": "按交易笔数",
  "GRider_part1_The_past_dJwH": "过去一周",
  "Fintech_Payment_installment_sNuL_KmUc": "新增账号",
  "Fintech_Payment_balance_gUth_Obds": "你可以查看和设置技术对接模式，查看证书并使用模拟测试工具。",
  "Fintech_Payment_activation_WTVF_WSBd": "验证码发送失败",
  "GRider_part1_Balance_tiVy": "余额",
  "Fintech_Payment_installment_bWei_KYLg": "开启此选项，表明你接受顾客使用分期支付",
  "Fintech_Payment_activation_UsPA_vZEF": "选择该模式后，你将无法自行更换到另一模式，确认选择该项吗？",
  "GRider_part1_Log_RdHb": "登出",
  "GRider_part2_Activated_jMQn": "已激活",
  "GRider_part2_Discontinued_KKYu": "已停用",
  "Fintech_Payment_activation_ZUlh_QWGc": "你可以在这里管理邮件订阅。订阅后，信息将会发送至你的邮箱",
  "GRider_part1_Request_for_Kprn": "申请退款",
  "GRider_part2_Generation_Time_esBq": "生成时间",
  "GRider_part2_Creating_roles_tNvf": "创建角色已超过100个，无法继续创建，请删除后重试",
  "Fintech_Payment_installment_WIri_etxW": "选择需要支持的最大分期数",
  "Fintech_Payment_activation_uJlg_hAxu": "输入验证码",
  "Fintech_Payment_activation_pYow_UUDd": "申请认证",
  "GRider_part2_Creating_more_RwTS": "创建用户已超过100个，无法继续创建，请删除后重试",
  "Fintech_Payment_installment_WvWU_CJNt": "不限",
  "Fintech_Payment_activation_JZLq_xGwA": "由{{brand_logo}}{{brand_logo}}提供支持",
  "Wallet_App_V2_Please_confirm_Pdiy": "请确认网络设置，稍后重试支付。",
  "GRider_part1_Payment_Method_vqAa": "支付方式",
  "GRider_testing_Set_Expired_XkNJ": "设置过期时间",
  "GRider_part1_Send_JlWu": "发送",
  "GRider_part2_Finished_ebgM": "完成",
  "GRider_part2_Discontinued_McUD": "停用",
  "Fintech_Payment_installment_ICgQ_zIBK": "支持的发卡行",
  "Fintech_Payment_balance_EbbT_fFaf": "包括所有的{{brand_name}}收取的手续费及税费（如有）；费用将在结算日当天统一收取。例如，如果结算规则为D+1，则10月1日的交易手续费及税费将在10月2日收取。",
  "Fintech_Payment_activation_eAZQ_tnfg": "快速订阅全部内容",
  "GRider_part1_Full_refund_yDPa": "全部退款",
  "Fintech_Payment_installment_amhe_tddV": "分期支付接入",
  "Fintech_Payment_activation_jSdX_KuOg": "再次发送",
  "Fintech_Payment_activation_FvVc_nLWa": "发送验证码",
  "GRider_testing_Payment_failed_OuxY": "支付失败",
  "GRider_part1_Recently_updated_bpQC": "最近更新时间",
  "Fintech_Payment_balance_LmVM_SHeq": "交易金额",
  "GRider_part2_User_Details_RoWh": "用户详情",
  "Fintech_Payment_balance_bIBn_FVAb": "昨日"
}