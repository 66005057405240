import omegaTracker from '@didi/omega-tracker-lite'
import { IAttrs } from '@didi/omega-tracker-lite/src/interface/event'
import { ITracker } from '@didi/omega-tracker-lite/src/interface/tracker'

/**
 * omega项目ID
 */
const appKey = 'omega16c5be87b4'

/**
 * 上报地址
 */
const uploadHost = 'omgup.didiglobal.com'

/**
 * 用户名,专用于：滴滴内部系统邮箱前缀
 */
 const userName = ''

/**
 * 用户uid
 */
 const userId = 0

/**
 * 公参
 */
 const commonAttrs = {
  // 产品线 
  pub_biz_line: "fin",
};


/**
 * omega初始化
 */
let Omega: ITracker | undefined
export const initOmega = () => {
  Omega = omegaTracker.getTracker({
    // 项目ID
    appKey,

    // 用户uid
    userId,

    // 用户名
    userName,

    // 上报地址
    uploadHost,
})
}

/**
 * omega设置参数
 * @param userId 用户ID
 * @param userName 用户名
 */
export const omegaSetConfig = ({
  userId,
  userName,
}:{
  userId?: number | string
  userName?: string
}) => {
  Omega?.setConfig({
    userId,
    userName,
  })
}

/**
 * omega埋点上报
 * @param eventId 事件ID
 * @param eventLabel 事件描述
 * @param attrs 埋点自定义参数
 */
export const trackEvent = (
  eventId: string,
  eventLabel: string | IAttrs | undefined,
  attrs: IAttrs | undefined,
) => {
  Omega?.trackEvent && Omega?.trackEvent(eventId,eventLabel,{
    ...commonAttrs,
    ...attrs,
    client_time: +new Date()
  })
}

export default Omega