import React, { Suspense, useState, useEffect, useCallback } from "react";
import { useRoutes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { getRoutes, RouteLocationListener } from "./routes";
import { GlobalContext, MenuItem, Permission } from "./store";
import { getLocalStorage, setLocalStorage } from "@/common/js/localStorage";
import Dialog from "@/components/Dialog";
import {
  getUser,
  getPermissions,
  getMerchantListInfo,
  logout,
} from "./services";
import { Skeleton, ConfigProvider, message, Button } from "antd";
import { i18nStart, i18nSetLang } from "@didi/i18n-plugin";
import { getAntdLocale } from "@/common/js/language";
import { setHeader } from "@/utils/request";
import { PassportURL } from "@/utils/utils";
import { init, setCity } from "@didi/elvish-lib-javascript/lib";
import { omegaSetConfig } from "@/utils/omega";
import "antd/dist/antd.less";
import "@/common/styles/reset.less";
import "@/common/styles/common.less";
import "@/common/styles/rtl.less";
import { isBR } from "@/common/js/constant"

// message 全局设置
message.config({
  duration: 5,
  maxCount: 3,
});

function App(): JSX.Element {
  const Routes = () => useRoutes(getRoutes());
  const [user, setUser] = useState({});
  const [language, setLanguage] = useState("");
  const [antdLocale, setAntdLocale] = useState();
  const [merchantList, setMerchantList] = useState<any>([]);
  const [menus, setMenus] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const [showPage, setShowPage] = useState<number>(0);
  const [loginVisible, setLoginVisible] = useState<boolean>(false);
  const [noPermission, setNoPermission] = useState<boolean>(false);
  const [merchant, setMerchant] = useState({
    merchantId: "",
    merchantName: "",
    countryCode: "",
    iconUrl: "",
    cityId: "",
    defaultLang: "",
  });

  const [country, setCountry] = useState("");

  useEffect(() => {
    i18nStart(require("./i18n/i18n-index"), require("./i18n/i18n-info"));
  }, []);

  const languageStore = (lang: string, id?: number) => {
    setLanguage(lang);
    i18nSetLang(lang);
    setHeader("lang", lang);
    setAntdLocale(getAntdLocale(lang));
    const storageId = id ? id : merchant.merchantId;
    const storageLang = getLocalStorage(`${storageId}-lang`);
    if ((storageLang && lang != storageLang) || !storageLang) {
      setLocalStorage(`${storageId}-lang`, lang);
    }
  };

  const filterMenus = (permissions: Permission[]) => {
    const menus: MenuItem[] = [];
    if (permissions && permissions.length) {
      for (let permission of permissions) {
        menus.push({
          ...permission,
          child: permission.child.filter((item) => item.type === 1),
        });
      }
    }
    return menus;
  };

  // 获取所有权限
  const getAllPermissions = () => {
    getPermissions().then((permissions) => {
      setPermissions(permissions);
      setMenus(filterMenus(permissions));
      setShowPage(showPage + 1);
    });
  };

  // 获取商户列表 & 设置默认商户
  const getMerchantList = () => {
    return getMerchantListInfo().then((data: any) => {
      const merchantList = data || [];
      setMerchantList(merchantList);
      if (!merchant.merchantId) {
        if (merchantList.length) {
          let currentMerchant = merchantList[0];
          setMerchant(currentMerchant);
          init("pt-BR").then(() => {
            setCity(currentMerchant.cityId);
          });
          setHeader("merchantId", currentMerchant.merchantId);
          // 增加判断
          const storageLang =
            getLocalStorage(`${currentMerchant.merchantId}-lang`) ||
            currentMerchant.defaultLang;
          languageStore(storageLang, currentMerchant.merchantId);
          return Promise.resolve({ noPermission: 0 });
        } else {        
          if (isBR) {
            // 巴西商户未激活或者无关联商户跳转自主注册url
            window.location.href =
              window.location.origin + "/self-onboarding/#/self-onboarding/";
          } else { // 墨西哥商户弹窗提示
            setLoginVisible(true);  
          }
                 
        }
      }
    });
  };

  // 初始化页面
  const refresh = () => {
    Promise.all([getMerchantList()]).then((data) => {
      if (data && data[0] && !data[0].noPermission) {
        getUser().then((user) => {
          setUser(user)

          // 设置omega参数
          omegaSetConfig({
            userId: user?.userId || '',
            userName: user?.userName || ''
          })
        });
        getAllPermissions();
      }
    });
  };

  // 切换商户
  const handleMerchantChange = (_: string, option: any) => {
    if(option?.value == 6666) {
      return  window.location.href = window.location.origin + '/self-onboarding/#/self-onboarding/'

       // todo 测试环境用
    // return  window.location.href = 'https://dsim284-api.intra.xiaojukeji.com/lightspeed/self-onboarding/#/self-onboarding';
      
    } else {
      const currMerchant = option?.merchantinfo;
      setLocalStorage('country', currMerchant.countryCode)
      setCountry(currMerchant.countryCode);
      setCity(currMerchant.cityId);
      setShowPage(showPage + 1);
      setMerchant(currMerchant);
      setHeader("merchantId", currMerchant.merchantId);
      const storageLang =
        getLocalStorage(`${currMerchant.merchantId}-lang`) ||
        currMerchant.defaultLang;
      languageStore(storageLang, currMerchant.merchantId);
      getAllPermissions();
    }
   
  };

  // 切换语言
  const handleLanguageChange = (lang: string) => {
    setShowPage(showPage + 1);
    languageStore(lang);
    getAllPermissions();
    setAntdLocale(getAntdLocale(lang));
  };

  useEffect(refresh, []);

  const logOut = () => {
    logout().then(() => {
      window.location.href = `${PassportURL(language)}`;
    });
  };

  const RouteBasename = process.env.BASE_PATH || "";
  return (
    <Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
      <ConfigProvider
        direction={/^ar/.test(language) ? "rtl" : "ltr"}
        locale={antdLocale}
      >
        <GlobalContext.Provider
          value={{
            user,
            menus,
            merchant,
            merchantList,
            language,
            permissions,
            country,
            handleMerchantChange,
            handleLanguageChange,
          }}
        >
          <BrowserRouter basename={RouteBasename}>
            <RouteLocationListener>
              {showPage > 0 ? <Routes /> : <Skeleton paragraph={{ rows: 4 }} />}
              {/* 导出确认弹窗 */}
              <Dialog
                visible={loginVisible}
                title="No permission"
                onCancel={() => setLoginVisible(false)}
                footer={[
                  <Button key="back" type="primary" onClick={logOut}>
                    return
                  </Button>,
                ]}
                width={440}
              >
                <div className="export-container">
                  <div className="export-tip">
                    {
                      "Merchant permissions error. Please contact your account manager."
                    }
                  </div>
                </div>
              </Dialog>
            </RouteLocationListener>
          </BrowserRouter>
        </GlobalContext.Provider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
