import { trackEvent } from "@/utils/omega";
import React, { useEffect, useRef } from "react";
import { RouteObject, Navigate, useLocation } from "react-router-dom";

// 路由集合
const RoutesCollection: Set<RouteObject> = new Set();

// Home
RegisterRoute(
  "/",
  React.lazy(() => import("./pages/Home"))
);
RegisterRoute(
  "/user/login",
  React.lazy(() => import("./pages/Login"))
);

// Transactions
RegisterRoute(
  "/transactions",
  React.lazy(() => import("./pages/Transactions"))
);

// Reports
RegisterRoute(
  "/reports/daily",
  React.lazy(() => import("./pages/Reports/Daily"))
);
RegisterRoute(
  "/reports/settlement",
  React.lazy(() => import("./pages/Reports/Settlement"))
);
RegisterRoute(
  "/reports/chargeback",
  React.lazy(() => import("./pages/Reports/Chargeback"))
);

// Finances
RegisterRoute(
  "/finance/balance",
  React.lazy(() => import("./pages/Finance/Balance"))
);
RegisterRoute(
  "/finance/withdrawal",
  React.lazy(() => import("./pages/Finance/Withdrawal"))
);

// Developers
RegisterRoute(
  "/developers/devconfig",
  React.lazy(() => import("./pages/Developers/Devconfig"))
);
// RegisterRoute('/developers/testing', React.lazy(() => import("./pages/Developers/Testing")))

// Account
RegisterRoute(
  "/account",
  React.lazy(() => import("./pages/Account"))
);

// Settings
RegisterRoute(
  "/settings/user",
  React.lazy(() => import("./pages/Settings/UserManagement"))
);
RegisterRoute(
  "/settings/role",
  React.lazy(() => import("./pages/Settings/RoleManagement"))
);
RegisterRoute(
  "/settings/installment",
  React.lazy(() => import("./pages/Settings/Installment"))
);

// not match route, redirect to home page.
RegisterRoute("*", () => <Navigate to="/" replace={true} />);

/**
 * Registers route
 * @author lawrencehou
 * @param path
 * @param Page
 */
export function RegisterRoute(
  path: string,
  Page: React.FC | React.LazyExoticComponent<() => JSX.Element>
): void {
  RoutesCollection.add({
    path,
    element: <Page />,
  });
}

/**
 * Gets reoutes
 * @author lawrencehou
 * @returns reoutes
 */
export function getRoutes(): RouteObject[] {
  return Array.from(RoutesCollection);
}

/**
 * 路由监听实现
 */
export function RouteLocationListener(props: { children: React.ReactNode }) {
  const location = useLocation()

  const locationState = useRef({
    // 前一页面
    from: '',
    // 当前页面
    to: '',
  })

  useEffect(() => {
    locationState.current.from = locationState.current.to
    locationState.current.to = location.pathname

    trackEvent('tech_merchant_page_sw',location.pathname,{
      // 当前页面
      current_page: location.pathname,
      // 前一页面
      from_page: locationState.current.from,
    })

  }, [location])

  return (
      <>
        {props.children}
      </>
  )
}
