import { MenuItem, Merchant, User } from "@/store"

import * as request from '@/utils/request'


/**
 * getUser 获取用户基本信息
 * @param url 
 */
export function getUser(): Promise<User> {
    // ('/merchant/api/outer/user/info'
    return request.get('/merchant/api/outer/user/current')
    // return request.get('/merchant/api/outer/user/info', {params: {xx: 111}})
}

/**
 * getMerchant 获取merchant List
 * @param url 
 */
export function getMerchantListInfo(): Promise<{data: Merchant[]}> {
    // merchant/api/outer/list
    return request.get('/merchant/api/outer/switch')
}


/**
 * getMenus 获取menu
 * @param url 
 */
 export function getMenus(): Promise<MenuItem[]> {
    return getPermissions().then(permissions => {
        if(permissions && permissions.length) {
            for(let permission of permissions) {
                permission.child = permission.child.filter(item => item.type === 1)
            }
        }
        return Promise.resolve(permissions)
    })
}

/**
 * Gets permissions 获取权限
 * @author lawrencehou
 * @returns permissions 
 */
export function getPermissions(): Promise<MenuItem[]> {
    return request.get<MenuItem[]>('/merchant/api/outer/permission', {}).then(permissions => {
        if(permissions && permissions.length) {
            for(let permission of permissions) {
                permission.child = permission.child || [];
            }
        }
        return Promise.resolve(permissions)
    })
}

/**
 * getEnums 获取 枚举
 * @param url 
 */
export function getEnums(enumNames: string[]) {
    // /merchant/api/outer/common/getEnums
    return request.post('/merchant/api/outer/common/getEnums', {enumNames})
}


/**
 * user logout 注销登录
 */
export function logout () {
    return request.post('/merchant/api/outer/user/logout', {})
}