import moment from "moment";
import { i18nTranslate } from "@didi/i18n-plugin";

// 正式环境
export const isProd = process.env.NODE_ENV === 'production';

export const isBR = window.location.host.startsWith('merchant.99pay.com.br');

/**
 * 预发域名变量
 * @description 在预发域名环境下，进行相关测试
 */
export const isPre = window.location.host.startsWith('didipay-pre.didiglobal.com');

// 所有环境配置
const Configs = {
	production: {
		publicAPIPath: '',
		passport: {
			url: 'https://page.didiglobal.com/public-biz/pc-login/3.1.1/index.html',
			// 巴西 200023 墨西哥 200026
			appID: isBR ? 200023 : 200026,
			source: 70001,
			role: 5001,
		},
	},
	development: {
		publicAPIPath: '',
		passport: {
			url: 'https://page.didiglobal.com/public-biz/pc-login/3.1.1/index.html',
			// 巴西 200023 墨西哥 200026
			appID: isBR ? 200023 : 200026,
			source: 70001,
			role: 5001,
		},

	},

}

// 当前配置
export const  DefaultConfigs = isProd ? Configs.production : Configs.development;

export const languageList = {
	'en-US': 'English', //'EN', 
	'pt-BR': 'Português', //'PT',
	'es-MX': 'Español', //'ES',
}

export const defaultLanguage = 'en-US'
export const DATE_FORMAT = 'YYYY-MM-DD';

export const dateRanges = (): {
    [key: string]: [moment.Moment, moment.Moment];
  } => {
	return {
		[i18nTranslate("GRider_part1_Today_YHmE")]: [moment(), moment()],
		[i18nTranslate("GRider_part1_Yesterday_Qqwf")]: [
		  moment().subtract(1, "day"),
		  moment().subtract(1, "day"),
		],
		[i18nTranslate("GRider_part1_The_past_dJwH")]: [
		  moment().subtract(1, "week"),
		  moment(),
		],
		[i18nTranslate("GRider_part1_The_past_mhPm")]: [
		  moment().subtract(1, "month"),
		  moment(),
		],
		[i18nTranslate("GRider_part1_The_last_IpXv")]: [
		  moment().subtract(3, "month"),
		  moment(),
		],
		[i18nTranslate("GRider_part1_The_past_KXKB")]: [
		  moment().subtract(1, "year"),
		  moment(),
		],
	}
}

export const defaultDateRange = [moment().subtract(20, 'day'), moment()] as [moment.Moment, moment.Moment]

export const todayDefaultDateRange = [moment(), moment()] as [moment.Moment, moment.Moment]

export const yestodayDefaultDateRange = [moment().subtract(1, 'day'), moment().subtract(1, 'day')] as [moment.Moment, moment.Moment]

export const initPagination = {
    current: 1,
    pageSize: 20
}

