import React, { Suspense, useState, useEffect } from 'react';


export type User = {
    userName?: string;
    userId?: string;
    userPhone?: string;
    countryCallingCode?: string;
    userEmail?: string;
    roleIdList?: number[];
    [x: string]: any;
}

export type Role = {
    roleId?: number;
    roleName?: string;
    permissionList?: number[];
}

export type Merchant = {
    merchantId: any;
    merchantName: string;
    countryCode: string;
    iconUrl?: string;
    cityId: string;
    ownershipType?: string;
    isNewMerchant?: number;
    brandName?: string; // 品牌名
    [x: string]: any;
}

export type Permission = {
    permissionId: number;
    url: string;
    name: string;
    type: number;
    child: MenuItem[] | []

}

export type MenuItem = Permission;

export const user: User = {
    userName: '',
    userId: '',
    userPhone: '',
    countryCallingCode: '',
    userEmail: '',
}

export const merchant: Merchant = {
    merchantId: undefined, // 商户编号
    merchantName: "", // 商户名称
    iconUrl: "", // 图标url
    countryCode: "", // 
    cityId: '',
}

export const merchantList: Merchant[] = []

export const permissions: Permission[] = []
export const menus: MenuItem[] = []

export const handleMerchantChange = (...args: any) => {}
export const handleLanguageChange = (...args: any) => {}
export const language: string = ''
export const country: string = ''
export const GlobalContext = React.createContext({
    user,
    menus,
    merchant,
    merchantList,
    language,
    permissions,
    country,
    handleMerchantChange,
    handleLanguageChange,
});