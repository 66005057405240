import { DefaultConfigs } from '@/common/js/constant';
import moment from 'moment';
import { getTimeStamp } from '@didi/elvish-lib-javascript/lib';
export const IsProd: boolean = process.env.NODE_ENV === 'production';

// passport 跳转地址
const { url, role, source, appID } = DefaultConfigs.passport;
export const PassportURL = (language?: string) => `${url}?${language?`lang=${language}`: ''}&role=${role}&source=${source}&appid=${appID}&redirectUrl=${encodeURIComponent(`${ window.location.origin }/merchant/api/outer/user/login?redirectUrl=${ window.location.href }`)}`

/**
 * 获取cookie
 * @param sKey 
 */
export const getCookieItem = (sKey: any) => {
    return (
        decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                    "(?:(?:^|.*;)\\s*" +
                    encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
                    "\\s*\\=\\s*([^;]*).*$)|^.*$"
                ),
                "$1"
            )
        ) || null
    );
};

/**
 * 
 * @param fn debounce
 * @param wait 
 */
export function debounce(fn: (e: any) => void, wait: number) {
    let timer: number | null = null
    return (props?: any) => {
        if (timer !== null) {
            clearTimeout(timer)
        }
        timer = window.setTimeout(() => {
            fn(props)
        }, wait)
    }
}


export function toDecimal(x: string) {
    var f = parseFloat(x);
    if (isNaN(f)) {
        return;
    }
    f = Math.round(Number(x)*100)/100;
    return f;
}

// format money 通用方法
export const renderMoney = (data: any, type: number = 0) => {
    switch(type) {
        // 金额 + 货币符号
        case 0:
            return data && data.valueDisplay + ' ' +  data.currency
        // 金额
        case 1:
            return data && data.valueDisplay
        // 货币符号
        case 2:
            return data && data.currency
    }
}

interface filterProps {
    name: string,
    value: number
}

/**
 * formatList format Select 筛选项
 * @param data 
 */
export const formatList = (data: any) => {
    const list: filterProps[] = []
    Object.keys(data).forEach((item) => {
        list.push({
            name: data[item],
            value: Number(item),
        })
    })
    return list
}

// format enums 通用方法
export const renderEnums = (data: any, enumsObj: any) => {
    return enumsObj[data]
}

// format 一天开始时间
export const getStartTimeStamp = (data: moment.Moment) => {
    return getTimeStamp(data?.format('YYYY-MM-DD') + ' 00:00:00')
    // return moment(data.format('YYYY-MM-DD') + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').unix()
}

// format 一天结束时间
export const getEndTimeStamp = (data: moment.Moment) => {
    return getTimeStamp(data?.format('YYYY-MM-DD') + ' 23:59:59')
    // return moment(data.format('YYYY-MM-DD') + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss').unix()
}