
export const LOCALSTORAGE_AD_DELIVERY_ACCOUNT = 'ad_delivery_account';

export const LOCALSTORAGE_AUDIENCE_SEGMENTATION_COUNTRY = 'audience_segmentation_country';


export function setLocalStorage(key: string, data: any) {
    if (!window.localStorage) {
        return '';
    }

    window.localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key: string) {
    if (!window.localStorage) {
        return;
    }
    const data = window.localStorage.getItem(key);
    try {
        if (data) {
            return JSON.parse(data);
        } 
    } catch {
        return data;
    }

}

export function removeLocalStorage(key: string) {
    if (!window.localStorage) {
        return;
    }

    try {
        window.localStorage.removeItem(key);
    } catch (e) {
        console.log(e)
    }
}

export function clearLocalStorage() {
    if (!window.localStorage) {
        return;
    }

    window.localStorage.clear();
}