module.exports = {
  "GRider_part1_Enter_NzrE": "Please enter",
  "GRider_part1_Available_surplus_qJnT": "Available Balance",
  "Fintech_Payment_activation_IzyA_cilV": "Step {{num}}",
  "Fintech_Payment_homepage__XSnB": "View transaction data and transaction volume trends",
  "GRider_part1_Daily_newspaper_LEkt": "Daily Reports",
  "Fintech_Payment_installment_YXNp_PFht": "To enable installment payments, please have your past transaction data ready, then contact our Business Developers or send an email to {{email_address}}",
  "GRider_part2_Beneficiaries_ofYu": "Beneficiary",
  "GRider_part1_Refusal_to_pkkj": "Chargeback Reports",
  "Fintech_Payment_balance_GMTI_Quqq": "Closing Balance = Beginning Balance + (Transactions − Fees − Withdrawals)",
  "GRider_part2_Tip_The_SSvk": "Note: The email address and mobile number cannot be edited once the user is created",
  "GRider_part1_Card_issuing_UMOj": "Issuer",
  "Fintech_Payment_activation_Gwim_BefB": "Before integrating, you need to first request credentials for your service provider. Please select your service provider",
  "GRider_part1_Yesterday_Qqwf": "Yesterday",
  "Fintech_Payment_activation_GjlD_wkpD": "Call {{brand_name}}’s API yourself",
  "Fintech_Payment_installment_hTIQ_Vrgf": "Account",
  "Fintech_Payment_balance_eQKY_heQN": "Developer Tools",
  "Fintech_Payment_installment_qiLa_XtZT": "Successful",
  "GRider_part1_Today_YHmE": "Today",
  "GRider_testing_Payment_VpTU": "Payment",
  "Fintech_Payment_homepage__RmdB": "Reports",
  "Wallet_App_Platform_Freezing_ppNk": "Frozen",
  "Fintech_Payment_installment_PFLR_ompu": "Accept Installment Payments",
  "Fintech_Payment_activation_vUKr_wcKq": "Credentials for {{platform_name}}",
  "Fintech_Payment_installment_hSSg_zUNZ": "Installment interest may vary depending on the number of installment periods or months.",
  "Fintech_Payment_balance_elMb_vZFR": "How to Read the Data",
  "Fintech_Payment_balance_KpPH_aCTM": "Account Details",
  "Fintech_Payment_balance_jhxV_SUuT": "Quick Access",
  "GRider_testing_Action_OCOw": "Actions",
  "Fintech_Payment_homepage__NBZW": "Hi!",
  "GRider_part1_Sent_successfully_kjRA": "Sent successfully",
  "GRider_part1_Description_wbwg": "Description",
  "GRider_part2_Download_WoXh": "Download",
  "GRider_part2_E_mail_jpRL": "Email Address",
  "GRider_part2_Associated_Billing_YMdm": "Associated Statement No.",
  "Fintech_Payment_activation_LhwA_UQij": "Request",
  "GRider_testing_The_test_mTcU": "You are using a test account. You can mock up test results by changing rules in each scenario.",
  "GRider_part1_Order_No_Ycoc": "Reference Number",
  "Fintech_Payment_activation_YFdK_GDwn": "Want to learn more? Click here",
  "GRider_part1_Total_balance_DpEi": "Total Balance",
  "Fintech_Payment_activation_DmlQ_MEHJ": "It looks like you haven’t completed integration yet",
  "GRider_part1_Statement_of_EvSW": "Settlement Reports",
  "GRider_part1_Reset_Password_qUom": "Reset Password",
  "Fintech_Payment_activation_Jgbl_pUBk": "Supported by",
  "GRider_part2_View_and_IngR": "View & Search",
  "GRider_testing_Rules_wFPn": "Rule",
  "Fintech_Payment_activation_ewlq_IoNo": "Verification Code Sent Successfully",
  "GRider_part2_Waiting_geZn": "Pending",
  "GRider_part2_Name_of_MryB": "Bank Name",
  "GRider_part2_callback_address_ktAH": "Callback URL",
  "Fintech_Payment_installment_KJiW_YtRu": "The number of installments is shown when the order amount is greater than this amount",
  "GRider_part1_Card_Expired_PDgL": "Expiration Date",
  "GRider_part2_Related_withdrawals_waNU": "Related Withdrawals",
  "Fintech_Payment_installment_rJDb_YgOl": "Installment Periods",
  "GRider_part2_File_size_YxTo": "File Size",
  "GRider_part2_Mid_ylNK": "MID",
  "GRider_part3_Successfully_replicated_waxb": "Copied",
  "GRider_part2_You_can_JwSD": "You can configure the callback URL to receive notifications",
  "GRider_part3_Incorrect_mobile_nRlv": "Invalid phone number format",
  "Fintech_Payment_activation_jxCH_yKtk": "Mock Test",
  "GRider_part2_Option_MwIV": "Selected",
  "GRider_part2_Platform_Public_awvm": "Platform Public Key",
  "GRider_part1_Cards_kUwm": "Issuing Country",
  "Fintech_Payment_homepage__fqqN": "Product Features",
  "GRider_part2_Update_Time_gjBJ": "Updated at",
  "GRider_part2_Bank_Account_loWF": "Account Number",
  "Fintech_Payment_installment_ymsu_wzNC": "Finance",
  "Fintech_Payment_activation_ihwN_LvcJ": "Next",
  "Fintech_Payment_installment_nABX_fEaU": "Contact our Business Developers and sign the additional terms",
  "GRider_part2_You_can_hYnM": "You can view and get your production keys here",
  "GRider_part1_Home_Page_uezP": "Homepage",
  "GRider_part2_Name_of_oyxC": "File Name",
  "GRider_part2_Production_keys_NWqC": "Production Keys",
  "GRider_part2_Save_Limz": "Save",
  "Fintech_Payment_installment_llHq_rMAE": "Home",
  "GRider_part2_Appid_BsOA": "AppID",
  "GRider_part2_Enter_a_zelI": "Enter name",
  "GRider_part2_Create_a_jFJI": "Create User",
  "Fintech_Payment_installment_hjTq_dlnR": "Installment Payment Plans",
  "Fintech_Payment_balance_NbxK_SaKX": "Allows you to view information about the bank account used for settlement",
  "GRider_part2_Contains_all_XBsh": "Includes all transactions created each day",
  "Fintech_Payment_installment_DVlZ_kYzm": "The number of installments you select also includes all plans with a lower number of installments. For example, if you select 6 installments, it will include all installment plans from 2 to 6 payment periods.",
  "Fintech_Payment_activation_Ifcd_qoWL": "Select this model if you work with an agency to build your website",
  "Fintech_Payment_installment_UPAc_xQXH": "Maximum Payment Amount",
  "Fintech_Payment_installment_cGuN_hnbl": "Statements",
  "GRider_part2_Reason_hMEF": "Reason",
  "GRider_part1_Refund_amount_DAYO": "Refund Amount",
  "GRider_testing_Overdue_period_XwCh": "Expiration Time",
  "Fintech_Payment_activation_ozQV_GveB": "Confirm",
  "GRider_part2_Is_a_Dnrr": "Are you sure you want to confirm this refund?",
  "GRider_part2_Character_Details_FNou": "Role Details",
  "GRider_part1_Between_the_eAiK": "Created at",
  "Fintech_Payment_installment_DnTv_Xkvt": "{{brand_name}} now supports installment payments with a credit card. This feature can help improve your order conversion rate and transaction volume.",
  "Fintech_Payment_balance_hSpF_MbAB": "Today",
  "Fintech_Payment_balance_nyOS_vxXE": "Click each module to complete settings or view information.",
  "Fintech_Payment_balance_CuLR_ULHc": "Closing Balance",
  "Fintech_Payment_installment_ZVzi_GWHv": "Issuing Bank",
  "GRider_part2_Callback_address_mWOg": "Callback URL Configuration",
  "GRider_part2_Store_Private_wkmJ": "Merchant Private Key",
  "GRider_part1_We_will_UNRe": "We’ll send your report to the following email address:",
  "GRider_part2_Cost_vEwI": "Fees",
  "GRider_part2_Withdrawal_Details_dHNg": "Withdrawal Details",
  "Fintech_Payment_installment_Vaha_KRAt": "Manually activate installment payment feature on merchant platform",
  "GRider_part1_The_past_mhPm": "Past month",
  "Fintech_Payment_balance_NFvu_eglf": "Beginning Balance",
  "GRider_part1_Payment_Details_DAiw": "Payment Details",
  "Fintech_Payment_installment_AVlb_xZKm": "{{specific_number}} months",
  "GRider_part2_Refunded_asmQ": "Refunded",
  "GRider_part1_Type_LsnR": "Type",
  "Fintech_Payment_activation_bMnh_ctqQ": "Select this model if you build your website and systems on your own",
  "Fintech_Payment_homepage__ypPH": "Developers",
  "GRider_part2_The_most_RGVh": "Last Login Time",
  "GRider_part2_Click_the_LDVM": "Click the button on the right to send via email",
  "GRider_part1_Order_No_YaNe": "Reference No./Merchant Order No.",
  "Fintech_Payment_installment_gQRp_NZeC": "You don’t need to make any changes. Everything will be configured from our side, including installment payment plans and the minimum payment amount for each installment period.",
  "GRider_part3_Refund_has_KQgL": "Refund requested",
  "Fintech_Payment_homepage__KZpP": "Welcome to the {{brand_name}} Merchant Platform. Here, you can find the data and features needed for daily operations.",
  "Fintech_Payment_balance_IpxB_ywzS": "Includes four types of transactions: payment, refund, chargeback and chargeback reversal. Of these, payment and chargeback reversal amounts are positive, and refund and chargeback amounts are negative.",
  "Fintech_Payment_activation_wjcD_GlZe": "After subscribing, you will be notified by email of any refunds resulting from transactions disputed by customers",
  "GRider_part1_Amount_paid_WEun": "Payment Amount",
  "Fintech_Payment_installment_FgNU_zmkD": "Enable Installment Payments in 3 Steps",
  "Wallet_App_Platform_The_money_vDON": "The amount in this account is the net amount of transactions and refunds less any relevant service fees. This amount will be transferred to your cash account once the conditions for settlement have been met.",
  "GRider_part1_Balance_to_QsRx": "Pending Balance",
  "GRider_testing_Set_Rules_zJNI": "Set rule",
  "Fintech_Payment_activation_qLYh_PPxk": "Select Your Provider",
  "Fintech_Payment_installment_qogy_NcYo": "Enable Installment Payments in 3 Steps",
  "GRider_part3_URL_format_iCKD": "Invalid URL format",
  "GRider_part2_Net_transactions_gAaN": "Net Transaction Amount",
  "Fintech_Payment_balance_yezl_qhlK": "Refresh",
  "Fintech_Payment_installment_IPpd_Svmd": "Fees",
  "GRider_part1_Today_s_hdrF": "Today’s Trends",
  "Fintech_Payment_activation_nxJQ_NgNJ": "Platform Availability and Maintenance",
  "GRider_part2_Action_cmsL": "Actions",
  "GRider_part1_Export_YZAn": "Export",
  "Fintech_Payment_installment_YUrU_qPmd": "Our installment payments feature is supported by most popular card issuers. Please check if your card issuer is included.",
  "Fintech_Payment_installment_riIt_DDvt": "Unlimited",
  "GRider_part2_Send_to_AtRh": "Send by email",
  "Fintech_Payment_activation_dgcg_YmoP": "Please select your service provider",
  "Fintech_Payment_installment_rOTK_eywP": "Please select the installment payment plans that you accept",
  "GRider_testing_Simulation_test_vGQn": "Mock Test",
  "Fintech_Payment_activation_uczM_UXBr": "Select This Model",
  "GRider_part3_Successfully_saved_lalL": "Saved",
  "GRider_part3_Refund_failed_VnYA": "Unable to refund. Please try again later",
  "GRider_testing_Scenarios_iISM": "Scenario",
  "GRider_part1_Confirmation_AyRV": "Confirm",
  "Fintech_Payment_balance_DtSD_XkwH": "Transaction Amount",
  "GRider_part2_Total_balance_YZYv": "Total Balance",
  "Fintech_Payment_installment_SQJZ_aEpL": "You can configure the settings for customer installment payments here",
  "Fintech_Payment_installment_kMba_amRL": "Minimum Payment Amount",
  "GRider_part1_Refundable_sZhr": "Refundable Amount",
  "GRider_part1_Amount_vtzS": "Amount",
  "Fintech_Payment_activation_obwW_aWIM": "Subscribe to All",
  "Fintech_Payment_installment_mEXH_WggE": "Role Management",
  "Fintech_Payment_balance_GbVq_mdCb": "Refers to the transfer amount after settlement. If a withdrawal is unsuccessful, the amount will be refunded immediately, and the withdrawal amount will be negative.",
  "Fintech_Payment_activation_kfLV_lJHI": "Requested token",
  "Fintech_Payment_installment_dddG_AtTw": "Number of Installments",
  "GRider_part2_Withdrawal_ID_SrUS": "Withdrawal ID",
  "GRider_part2_All_transactions_zvvZ": "Includes all settlement transactions",
  "GRider_part2_Cash_Amount_LJKk": "Withdrawals",
  "GRider_testing_Select_the_zelH": "Select scenario",
  "GRider_part2_Unable_to_yJGm": "The user’s profile cannot be recovered once deleted",
  "GRider_part2_Resumed_after_hqrX": "Resend in {{time}}s",
  "GRider_part1_Clear_pScF": "Clear",
  "GRider_part2_Delete_the_lFFi": "Delete This User?",
  "Fintech_Payment_balance_hfIW_kqDR": "Withdrawals",
  "Fintech_Payment_installment_YUyv_ddsJ": "Pay with Balance",
  "Fintech_Payment_activation_wfOI_wbgn": "Enter Verification Code",
  "GRider_part2_Total_transaction_vOfv": "Total Transaction Amount",
  "GRider_testing_Test_account_jNcp": "Test Account",
  "GRider_part1_Cancel_VJqj": "Cancelled",
  "GRider_part2_Activation_oBkK": "Activate",
  "GRider_part1_Transaction_Details_QlYj": "Transaction Details",
  "Fintech_Payment_installment_iwhg_GXAN": "Subscriptions",
  "Fintech_Payment_installment_mBYW_wReM": "Pay in Installments",
  "Fintech_Payment_activation_FXqF_QMDQ": "Agency Integration",
  "Fintech_Payment_homepage__ckdF": "Get detailed transaction, settlement and chargeback reports",
  "GRider_part2_Role_kDjH": "Roles",
  "GRider_part2_Copy_mepW": "Copy",
  "GRider_part1_User_management_XlPK": "Manage Users",
  "GRider_part1_Role_management_BGrU": "Manage Roles",
  "Fintech_Payment_activation_NHCc_pjPW": "Subscriptions",
  "GRider_part3_The_key_BlJh": "The key was sent to the administrator’s email ({{email}})",
  "GRider_testing_Key_and_pUWf": "Key and Callback",
  "Fintech_Payment_activation_WPSw_nvwn": "{{brand_name}}’s API will be called by your service provider",
  "GRider_part3_Account_Details_Rhrj": "Account Details",
  "Fintech_Payment_installment_nZUF_eajs": "Your customers can pay in installments",
  "GRider_part1_Status_QkjC": "Status",
  "GRider_part2_Delete_Mdzk": "Delete",
  "Fintech_Payment_balance_OMHo_KuHt": "Select comparison period",
  "GRider_part3_Tip_Modify_ZuaO": "Reminder: It can still be edited after saving",
  "GRider_part2_Did_you_zYqT": "Delete This Role?",
  "GRider_part2_Create_a_cXhs": "Create a New User",
  "GRider_part1_Withdrawal_hMai": "Withdrawals",
  "GRider_part3_Contains_daily_mqQx": "Includes the chargeback data generated and updated each day",
  "Fintech_Payment_balance_JuIe_SCxH": "as of {{specific_hour}}",
  "GRider_part1_The_past_KXKB": "Past year",
  "Fintech_Payment_homepage__mrUl": "Access complete developer documentation and mock test functionality",
  "Fintech_Payment_activation_ZSUb_fBQj": "Credentials",
  "Fintech_Payment_activation_kngw_rjJO": "Direct Integration",
  "Fintech_Payment_installment_HVdy_lDaD": "Transactions",
  "Fintech_Payment_homepage__iUXv": "Data Dashboard",
  "GRider_part3_Cancel_rVRs": "Cancel",
  "Fintech_Payment_activation_tNUu_MyKS": "This will help keep your account secure. A verification code has been sent to {{email_address}}",
  "Fintech_Payment_activation_GNJM_joSQ": "Configure your system using parameters from {{brand_name}} on your own",
  "Fintech_Payment_onboarding_mhXP_NLLg": "Invalid format",
  "GRider_testing_Test_account_nnib": "Test Account",
  "Wallet_App_Platform_This_account_TkJu": "This account is the net amount of transactions flagged for potential risk. Please check your email for more info on how to submit an appeal.",
  "GRider_part2_Select_permission_lHal": "Select permissions",
  "Fintech_Payment_balance_SqOx_AyAJ": "Refers to the total balance as of 00:00 ({{time_zone}}) on the specified day. This amount is the same as the closing balance of the previous day.",
  "GRider_part2_Here_is_JDga": "Your settlement bank account information is displayed here. If you need to update your information, please contact your Account Manager.",
  "GRider_part2_Creating_a_zflX": "Create New Role",
  "GRider_part2_Create_a_SGfq": "Create a New Role",
  "Fintech_Payment_activation_zjqY_gLat": "Please select an integration model first",
  "GRider_part1_Related_Transactions_UIBX": "Related Transactions",
  "GRider_part2_Name_vbyE": "Name",
  "Fintech_Payment_activation_LjzQ_NEsx": "Refund Notifications",
  "Fintech_Payment_balance_tuMc_oNCn": "Fees",
  "Fintech_Payment_balance_DbDe_VdbR": "The length of the selected period cannot exceed the time range on the left",
  "GRider_part2_Date_hYez": "Date",
  "GRider_part1_Accounts_BFvS": "Account",
  "Fintech_Payment_activation_JQpN_Tszu": "Please confirm",
  "Fintech_Payment_activation_Ddpw_LpmL": "Requested key",
  "Wallet_App_Platform_Thawing_hqVt": "Unfreeze",
  "GRider_testing_Successful_payment_Yanu": "Payment successful",
  "Fintech_Payment_activation_HqZu_HXzt": "Perform mock testing on {{brand_name}} before your official launch",
  "Fintech_Payment_activation_NqtQ_sjWJ": "Direct Integration",
  "Wallet_App_Platform_Funds_from_uGZs": "The amount in this account will be automatically deposited to your bank account.",
  "GRider_part2_Telephone_Number_vMII": "Mobile No.",
  "GRider_part2_Withdrawal_refund_zKZp": "Withdrawal Refund",
  "GRider_part2_Enter_email_MOOA": "Enter email",
  "GRider_part2_Edit_EsRp": "Edit",
  "Fintech_Payment_installment_bSZx_AgaB": "User Management",
  "GRider_part1_Card_Type_QJgr": "Card Type",
  "Fintech_Payment_activation_tnxr_cHMD": "Integration via Platform",
  "Fintech_Payment_activation_FHYl_spTb": "Complete testing before your official launch using the test account provided by your service provider",
  "GRider_part1_Should_be_wIqf": "Apply",
  "Fintech_Payment_installment_CqfD_Ivza": "Fees",
  "GRider_part1_The_last_IpXv": "Past 3 months",
  "Fintech_Payment_installment_heim_nBgM": "Set the minimum installment amount for each payment period",
  "Wallet_App_Platform_Freeze_surplus_Woql": "Frozen Balance",
  "Fintech_Payment_activation_ZQbh_hGRy": "After subscribing, any information about platform availability, service disruptions and routine maintenance will be sent to your email",
  "Wallet_App_Platform_Freezing_lsVa": "Freeze",
  "GRider_part1_Order_No_OZVW": "Merchant Order No.",
  "GRider_part1_Developers_UQKx": "Developers",
  "GRider_part1_Transactions_KxSe": "Transactions",
  "Fintech_Payment_balance_mwQV_TfRo": "View Details",
  "Fintech_Payment_installment_imFR_PvzU": "Supported Card Issuers",
  "GRider_testing_Change_vqmJ": "Edit",
  "Fintech_Payment_installment_gveY_fVAe": "Settings",
  "GRider_part1_Export_Transactions_RRhr": "Export Transaction Report",
  "Fintech_Payment_installment_KSvh_imKX": "Payment Method",
  "GRider_part2_Enter_your_GLIm": "Enter mobile no.",
  "Fintech_Payment_installment_RYcT_IbTU": "Card Brand",
  "Fintech_Payment_activation_xoUl_LHiq": "Complete integration by sending credentials from {{brand_name}} to your service provider",
  "GRider_part1_Card_number_fuXk": "Card No.",
  "Fintech_Payment_activation_JIXy_NPez": "Back",
  "Fintech_Payment_activation_isyA_IErj": "Cancel",
  "GRider_part2_Editing_User_zQpH": "Edit User Information",
  "GRider_part2_Editing_role_Jtat": "Edit Role Information",
  "Fintech_Payment_installment_jEkP_xnAG": "Installment Payment Settings",
  "Fintech_Payment_installment_RysT_ofxZ": "Integrate Installment Payments",
  "Fintech_Payment_balance_TDMh_lWOL": "Number of Transactions",
  "GRider_part1_The_past_dJwH": "Past week",
  "Fintech_Payment_installment_sNuL_KmUc": "Add New Account",
  "Fintech_Payment_balance_gUth_Obds": "Allows you to check and set technical integration models, view credentials and use mock test tools.",
  "Fintech_Payment_activation_WTVF_WSBd": "Failed to Send Verification Code",
  "GRider_part1_Balance_tiVy": "Balance",
  "Fintech_Payment_installment_bWei_KYLg": "By turning on this option, you will permit customers to pay in installments",
  "Fintech_Payment_activation_UsPA_vZEF": "If you select this model, you won’t be able to switch to another one on your own. Are you sure you want to continue?",
  "GRider_part1_Log_RdHb": "Sign out",
  "GRider_part2_Activated_jMQn": "Activated",
  "GRider_part2_Discontinued_KKYu": "Disabled",
  "Fintech_Payment_activation_ZUlh_QWGc": "You can manage email subscription here. After subscribing, the information will be sent to your email address",
  "GRider_part1_Request_for_Kprn": "Request Refund",
  "GRider_part2_Generation_Time_esBq": "Generated on",
  "GRider_part2_Creating_roles_tNvf": "There are currently more than 100 roles. You’ll need to delete one before you can create a new role.",
  "Fintech_Payment_installment_WIri_etxW": "Select the maximum number of installments",
  "Fintech_Payment_activation_uJlg_hAxu": "Enter Verification Code",
  "Fintech_Payment_activation_pYow_UUDd": "Request Credentials",
  "GRider_part2_Creating_more_RwTS": "There are currently more than 100 users. You’ll need to delete one before you can create a new user.",
  "Fintech_Payment_installment_WvWU_CJNt": "Unlimited",
  "Fintech_Payment_activation_JZLq_xGwA": "Supported by {{brand_logo}}{{brand_logo}}",
  "Wallet_App_V2_Please_confirm_Pdiy": "Please check your network settings and try again later.",
  "GRider_part1_Payment_Method_vqAa": "Payment Method",
  "GRider_testing_Set_Expired_XkNJ": "Set expiration time",
  "GRider_part1_Send_JlWu": "Send",
  "GRider_part2_Finished_ebgM": "Completed",
  "GRider_part2_Discontinued_McUD": "Disable",
  "Fintech_Payment_installment_ICgQ_zIBK": "Supported Card Issuers",
  "Fintech_Payment_balance_EbbT_fFaf": "Includes all service fees and tax (if any) charged by {{brand_name}}; fees are charged on the settlement day. For example, if the settlement rule is set as D+1, transaction fees and tax incurred on Oct. 1 will be charged on Oct. 2.",
  "Fintech_Payment_activation_eAZQ_tnfg": "Use this option to quickly subscribe to all available information",
  "GRider_part1_Full_refund_yDPa": "Full Refund",
  "Fintech_Payment_installment_amhe_tddV": "Integrate Installment Payments",
  "Fintech_Payment_activation_jSdX_KuOg": "Resend",
  "Fintech_Payment_activation_FvVc_nLWa": "Send Verification Code",
  "GRider_testing_Payment_failed_OuxY": "Payment failed",
  "GRider_part1_Recently_updated_bpQC": "Last updated on",
  "Fintech_Payment_balance_LmVM_SHeq": "Transaction Amount",
  "GRider_part2_User_Details_RoWh": "User Details",
  "Fintech_Payment_balance_bIBn_FVAb": "Yesterday"
}