module.exports = {
  "GRider_part1_Enter_NzrE": "Ingresar",
  "GRider_part1_Available_surplus_qJnT": "Saldo disponible",
  "Fintech_Payment_activation_IzyA_cilV": "Paso {{num}}",
  "Fintech_Payment_homepage__XSnB": "Puedes ver datos de los movimientos y las tendencias de volumen de los movimientos",
  "GRider_part1_Daily_newspaper_LEkt": "Informes diarios",
  "Fintech_Payment_installment_YXNp_PFht": "Para activar los meses sin intereses, necesitarás los datos de movimientos pasados y contactar a nuestros representantes comerciales, o enviar un correo electrónico a {{email_address}}",
  "GRider_part2_Beneficiaries_ofYu": "Beneficiario",
  "GRider_part1_Refusal_to_pkkj": "Informes de contracargo",
  "Fintech_Payment_balance_GMTI_Quqq": "Saldo al cierre = Saldo inicial + (Movimientos - Comisiones - Retiros)",
  "GRider_part2_Tip_The_SSvk": "Nota: la dirección de correo electrónico y el número de teléfono no se pueden editar una vez que se crea el usuario",
  "GRider_part1_Card_issuing_UMOj": "Emisor",
  "Fintech_Payment_activation_Gwim_BefB": "Antes de integrar, debes solicitar las credenciales del proveedor de servicios. Por favor, selecciona tu proveedor de servicios.",
  "GRider_part1_Yesterday_Qqwf": "Ayer",
  "Fintech_Payment_activation_GjlD_wkpD": "Llama a las API de {{brand_name}} por tu cuenta",
  "Fintech_Payment_installment_hTIQ_Vrgf": "Cuenta",
  "Fintech_Payment_balance_eQKY_heQN": "Herramientas del desarrollador",
  "Fintech_Payment_installment_qiLa_XtZT": "Completado",
  "GRider_part1_Today_YHmE": "Hoy",
  "GRider_testing_Payment_VpTU": "Pago",
  "Fintech_Payment_homepage__RmdB": "Informes",
  "Wallet_App_Platform_Freezing_ppNk": "Congelado",
  "Fintech_Payment_installment_PFLR_ompu": "Acepta meses sin intereses",
  "Fintech_Payment_activation_vUKr_wcKq": "Credenciales de {{platform_name}}",
  "Fintech_Payment_installment_hSSg_zUNZ": "Los intereses de los meses sin intereses pueden variar según el número de periodos o meses de pago.",
  "Fintech_Payment_balance_elMb_vZFR": "Cómo leer datos",
  "Fintech_Payment_balance_KpPH_aCTM": "Detalles de mi cuenta",
  "Fintech_Payment_balance_jhxV_SUuT": "Acceso rápido",
  "GRider_testing_Action_OCOw": "Acciones",
  "Fintech_Payment_homepage__NBZW": "¡Hola!",
  "GRider_part1_Sent_successfully_kjRA": "Enviado correctamente",
  "GRider_part1_Description_wbwg": "Descripción",
  "GRider_part2_Download_WoXh": "Descargar",
  "GRider_part2_E_mail_jpRL": "Correo electrónico",
  "GRider_part2_Associated_Billing_YMdm": "Número de liquidación asociado",
  "Fintech_Payment_activation_LhwA_UQij": "Solicitar",
  "GRider_testing_The_test_mTcU": "Estás usando una cuenta de prueba. Puedes simular diferentes resultados cambiando las reglas para cada escenario.",
  "GRider_part1_Order_No_Ycoc": "Número de referencia",
  "Fintech_Payment_activation_YFdK_GDwn": "Para más información, haz clic aquí",
  "GRider_part1_Total_balance_DpEi": "Saldo total",
  "Fintech_Payment_activation_DmlQ_MEHJ": "Parece que todavía no has completado la integración",
  "GRider_part1_Statement_of_EvSW": "Reporte de pagos",
  "GRider_part1_Reset_Password_qUom": "Restablecer contraseña",
  "Fintech_Payment_activation_Jgbl_pUBk": "Con tecnología de",
  "GRider_part2_View_and_IngR": "Ver y buscar",
  "GRider_testing_Rules_wFPn": "Regla",
  "Fintech_Payment_activation_ewlq_IoNo": "Se envió el código de verificación",
  "GRider_part2_Waiting_geZn": "Pendiente",
  "GRider_part2_Name_of_MryB": "Nombre del banco",
  "GRider_part2_callback_address_ktAH": "URL de devolución",
  "Fintech_Payment_installment_KJiW_YtRu": "El número de meses sin intereses se muestra cuando el monto del pedido es mayor a esta cantidad",
  "GRider_part1_Card_Expired_PDgL": "Fecha de vencimiento",
  "GRider_part2_Related_withdrawals_waNU": "Retiros relacionados",
  "Fintech_Payment_installment_rJDb_YgOl": "Plazos de meses sin intereses",
  "GRider_part2_File_size_YxTo": "Tamaño del archivo",
  "GRider_part2_Mid_ylNK": "MID",
  "GRider_part3_Successfully_replicated_waxb": "Copiado",
  "GRider_part2_You_can_JwSD": "Puedes configurar la URL de devolución para recibir notificaciones",
  "GRider_part3_Incorrect_mobile_nRlv": "El formato del teléfono no es válido",
  "Fintech_Payment_activation_jxCH_yKtk": "Simulación",
  "GRider_part2_Option_MwIV": "Seleccionado(s)",
  "GRider_part2_Platform_Public_awvm": "Clave pública de la plataforma",
  "GRider_part1_Cards_kUwm": "País de emisión",
  "Fintech_Payment_homepage__fqqN": "Funciones del producto",
  "GRider_part2_Update_Time_gjBJ": "Actualizado el",
  "GRider_part2_Bank_Account_loWF": "Número de cuenta",
  "Fintech_Payment_installment_ymsu_wzNC": "Finanzas",
  "Fintech_Payment_activation_ihwN_LvcJ": "Siguiente",
  "Fintech_Payment_installment_nABX_fEaU": "Contacta a uno de nuestros representantes comerciales para firmar los términos adicionales",
  "GRider_part2_You_can_hYnM": "Puedes ver y obtener tus claves de producción aquí",
  "GRider_part1_Home_Page_uezP": "Inicio",
  "GRider_part2_Name_of_oyxC": "Nombre del archivo",
  "GRider_part2_Production_keys_NWqC": "Claves de producción",
  "GRider_part2_Save_Limz": "Guardar",
  "Fintech_Payment_installment_llHq_rMAE": "Inicio",
  "GRider_part2_Appid_BsOA": "AppID",
  "GRider_part2_Enter_a_zelI": "Ingresar nombre",
  "GRider_part2_Create_a_jFJI": "Crear usuario",
  "Fintech_Payment_installment_hjTq_dlnR": "Plazos de meses sin intereses",
  "Fintech_Payment_balance_NbxK_SaKX": "Te permite ver información sobre la cuenta bancaria utilizada para los pagos",
  "GRider_part2_Contains_all_XBsh": "Incluye todas las transacciones creadas cada día",
  "Fintech_Payment_installment_DVlZ_kYzm": "El número de meses sin intereses que selecciones incluirá también todos los planes con menos cantidad de meses sin intereses. Por ejemplo, si seleccionas 6 meses, incluirá todos los planes de entre 2 y 6 meses.",
  "Fintech_Payment_activation_Ifcd_qoWL": "Selecciona este modelo si trabajas con una agencia para construir tu sitio web",
  "Fintech_Payment_installment_UPAc_xQXH": "Monto de pago máximo",
  "Fintech_Payment_installment_cGuN_hnbl": "Estados de cuenta",
  "GRider_part2_Reason_hMEF": "Motivo",
  "GRider_part1_Refund_amount_DAYO": "Monto del reembolso",
  "GRider_testing_Overdue_period_XwCh": "Fecha y hora de vencimiento",
  "Fintech_Payment_activation_ozQV_GveB": "Confirmar",
  "GRider_part2_Is_a_Dnrr": "¿Seguro que deseas confirmar este reembolso?",
  "GRider_part2_Character_Details_FNou": "Detalles del rol",
  "GRider_part1_Between_the_eAiK": "Creado el",
  "Fintech_Payment_installment_DnTv_Xkvt": "{{brand_name}} ahora acepta pagos a meses sin intereses con tarjeta de crédito. Esta opción puede ayudar a mejorar tu tasa de conversión de pedidos y el volumen de movimientos.",
  "Fintech_Payment_balance_hSpF_MbAB": "Hoy",
  "Fintech_Payment_balance_nyOS_vxXE": "Haz clic en cada módulo para completar la configuración o ver la información.",
  "Fintech_Payment_balance_CuLR_ULHc": "Saldo al cierre",
  "Fintech_Payment_installment_ZVzi_GWHv": "Entidad emisora",
  "GRider_part2_Callback_address_mWOg": "Configuración de URL de devolución",
  "GRider_part2_Store_Private_wkmJ": "Clave privada del comercio",
  "GRider_part1_We_will_UNRe": "Enviaremos tu informe a la siguiente dirección de correo electrónico:",
  "GRider_part2_Cost_vEwI": "Comisiones",
  "GRider_part2_Withdrawal_Details_dHNg": "Detalles del retiro",
  "Fintech_Payment_installment_Vaha_KRAt": "Activa de forma manual la opción de meses sin intereses en la plataforma del comercio",
  "GRider_part1_The_past_mhPm": "Mes pasado",
  "Fintech_Payment_balance_NFvu_eglf": "Saldo inicial",
  "GRider_part1_Payment_Details_DAiw": "Detalles del pago",
  "Fintech_Payment_installment_AVlb_xZKm": "{{specific_number}} meses",
  "GRider_part2_Refunded_asmQ": "Reembolsado",
  "GRider_part1_Type_LsnR": "Tipo",
  "Fintech_Payment_activation_bMnh_ctqQ": "Selecciona este modelo si construyes tú mismo tu sitio web y los sistemas",
  "Fintech_Payment_homepage__ypPH": "Desarrolladores",
  "GRider_part2_The_most_RGVh": "Fecha y hora del último inicio de sesión",
  "GRider_part2_Click_the_LDVM": "Haz clic en el botón de la derecha para enviarla por correo electrónico:",
  "GRider_part1_Order_No_YaNe": "No. referen./orden de comercio",
  "Fintech_Payment_installment_gQRp_NZeC": "No hace falta que hagas cambios. Nos encargaremos de configurar todo, incluyendo los plazos de meses sin intereses y el pago mínimo para cada periodo de pago.",
  "GRider_part3_Refund_has_KQgL": "Reembolso solicitado",
  "Fintech_Payment_homepage__KZpP": "Te damos la bienvenida a la Plataforma para comercios de {{brand_name}}. Aquí, podrás encontrar la información y las funciones necesarias para tus operaciones diarias.",
  "Fintech_Payment_balance_IpxB_ywzS": "Incluye cuatro tipos de movimientos: pagos, reembolsos, contracargos y devoluciones de contracargos. De estos, los montos de pagos y devoluciones de contracargos son positivos y los montos de reembolsos y contracargos son negativos.",
  "Fintech_Payment_activation_wjcD_GlZe": "Luego de suscribirte, recibirás por correo electrónico notificaciones de reembolsos por movimientos para los que los clientes solicitaron aclaraciones.",
  "GRider_part1_Amount_paid_WEun": "Monto del pago",
  "Fintech_Payment_installment_FgNU_zmkD": "Activa los meses sin intereses en 3 pasos",
  "Wallet_App_Platform_The_money_vDON": "El monto de la cuenta es la cantidad neta de las transacciones y los reembolsos menos las tarifas de servicio correspondientes. Este monto será transferido a tu cuenta una vez que se cumplan las condiciones del pago.",
  "GRider_part1_Balance_to_QsRx": "Saldo pendiente",
  "GRider_testing_Set_Rules_zJNI": "Establecer regla",
  "Fintech_Payment_activation_qLYh_PPxk": "Seleccionar proveedor",
  "Fintech_Payment_installment_qogy_NcYo": "Activa los meses sin intereses en 3 pasos",
  "GRider_part3_URL_format_iCKD": "Formato de URL inválido",
  "GRider_part2_Net_transactions_gAaN": "Monto neto de la transacción",
  "Fintech_Payment_balance_yezl_qhlK": "Actualizar",
  "Fintech_Payment_installment_IPpd_Svmd": "Comisiones",
  "GRider_part1_Today_s_hdrF": "Tendencias del día",
  "Fintech_Payment_activation_nxJQ_NgNJ": "Disponibilidad de la plataforma y mantenimiento",
  "GRider_part2_Action_cmsL": "Acciones",
  "GRider_part1_Export_YZAn": "Exportar",
  "Fintech_Payment_installment_YUrU_qPmd": "La opción de diferir a meses sin intereses es compatible con la mayoría de las entidades emisoras de tarjetas. Revisa si la tuya está incluida.",
  "Fintech_Payment_installment_riIt_DDvt": "Ilimitado",
  "GRider_part2_Send_to_AtRh": "Enviar por correo electrónico",
  "Fintech_Payment_activation_dgcg_YmoP": "Por favor, selecciona tu proveedor de servicios",
  "Fintech_Payment_installment_rOTK_eywP": "Selecciona los plazos de meses sin intereses que quieras aceptar",
  "GRider_testing_Simulation_test_vGQn": "Prueba de simulación",
  "Fintech_Payment_activation_uczM_UXBr": "Elegir este modelo",
  "GRider_part3_Successfully_saved_lalL": "Guardado",
  "GRider_part3_Refund_failed_VnYA": "No se pudo hacer el reembolso. Por favor, inténtalo de nuevo más tarde.",
  "GRider_testing_Scenarios_iISM": "Escenario",
  "GRider_part1_Confirmation_AyRV": "Confirmar",
  "Fintech_Payment_balance_DtSD_XkwH": "Monto del movimiento",
  "GRider_part2_Total_balance_YZYv": "Saldo total",
  "Fintech_Payment_installment_SQJZ_aEpL": "Puedes configurar los meses sin intereses de los clientes aquí",
  "Fintech_Payment_installment_kMba_amRL": "Monto de pago mínimo",
  "GRider_part1_Refundable_sZhr": "Monto reembolsable",
  "GRider_part1_Amount_vtzS": "Monto",
  "Fintech_Payment_activation_obwW_aWIM": "Suscribirse a todo",
  "Fintech_Payment_installment_mEXH_WggE": "Administración de funciones",
  "Fintech_Payment_balance_GbVq_mdCb": "Se refiere a los montos transferidos después del pago. Si un retiro no es completado, el monto será reembolsado inmediatamente, y el monto del retiro será negativo.",
  "Fintech_Payment_activation_kfLV_lJHI": "Token solicitado",
  "Fintech_Payment_installment_dddG_AtTw": "Número de meses sin intereses",
  "GRider_part2_Withdrawal_ID_SrUS": "Identificación del retiro",
  "GRider_part2_All_transactions_zvvZ": "Incluye todas las transacciones de liquidación",
  "GRider_part2_Cash_Amount_LJKk": "Retiros",
  "GRider_testing_Select_the_zelH": "Seleccionar escenario",
  "GRider_part2_Unable_to_yJGm": "El perfil del usuario no se puede recuperar una vez eliminado",
  "GRider_part2_Resumed_after_hqrX": "Reenviar en {{time}} s",
  "GRider_part1_Clear_pScF": "Borrar",
  "GRider_part2_Delete_the_lFFi": "¿Eliminar este usuario?",
  "Fintech_Payment_balance_hfIW_kqDR": "Retiros",
  "Fintech_Payment_installment_YUyv_ddsJ": "Pagar con el Saldo",
  "Fintech_Payment_activation_wfOI_wbgn": "Ingresa el código de verificación",
  "GRider_part2_Total_transaction_vOfv": "Monto total de la transacción",
  "GRider_testing_Test_account_jNcp": "Cuenta de prueba",
  "GRider_part1_Cancel_VJqj": "Cancelado",
  "GRider_part2_Activation_oBkK": "Activar",
  "GRider_part1_Transaction_Details_QlYj": "Detalles de la transacción",
  "Fintech_Payment_installment_iwhg_GXAN": "Suscripciones",
  "Fintech_Payment_installment_mBYW_wReM": "Pagar a meses sin intereses",
  "Fintech_Payment_activation_FXqF_QMDQ": "Integración de agencia",
  "Fintech_Payment_homepage__ckdF": "Puedes obtener informes detallados de los movimientos, los pagos y los contracargos",
  "GRider_part2_Role_kDjH": "Rol(es)",
  "GRider_part2_Copy_mepW": "Copiar",
  "GRider_part1_User_management_XlPK": "Administrar usuarios",
  "GRider_part1_Role_management_BGrU": "Administrar roles",
  "Fintech_Payment_activation_NHCc_pjPW": "Suscripciones",
  "GRider_part3_The_key_BlJh": "Se envió la clave al correo electrónico del administrador ({{email}}).",
  "GRider_testing_Key_and_pUWf": "Clave y llamada",
  "Fintech_Payment_activation_WPSw_nvwn": "Tu proveedor de servicios llamará a la API de {{brand_name}}",
  "GRider_part3_Account_Details_Rhrj": "Detalles de la cuenta",
  "Fintech_Payment_installment_nZUF_eajs": "Tus clientes pueden pagar a meses sin intereses",
  "GRider_part1_Status_QkjC": "Estado",
  "GRider_part2_Delete_Mdzk": "Eliminar",
  "Fintech_Payment_balance_OMHo_KuHt": "Selecciona el periodo a comparar",
  "GRider_part3_Tip_Modify_ZuaO": "Recuerda: puedes editar incluso después de guardar.",
  "GRider_part2_Did_you_zYqT": "¿Eliminar este rol?",
  "GRider_part2_Create_a_cXhs": "Crear un nuevo usuario",
  "GRider_part1_Withdrawal_hMai": "Retiros",
  "GRider_part3_Contains_daily_mqQx": "Incluye los datos actualizados diariamente de los contracargos generados.",
  "Fintech_Payment_balance_JuIe_SCxH": "desde las {{specific_hour}}",
  "GRider_part1_The_past_KXKB": "Año pasado",
  "Fintech_Payment_homepage__mrUl": "Accede a la documentación completa de los desarrolladores y a las funcionalidades de pruebas de simulación",
  "Fintech_Payment_activation_ZSUb_fBQj": "Credenciales",
  "Fintech_Payment_activation_kngw_rjJO": "Integración directa",
  "Fintech_Payment_installment_HVdy_lDaD": "Movimientos",
  "Fintech_Payment_homepage__iUXv": "Panel de datos",
  "GRider_part3_Cancel_rVRs": "Cancelar",
  "Fintech_Payment_activation_tNUu_MyKS": "Se envió un código de verificación al {{email_address}}. Esto ayudará a mantener la seguridad de tu cuenta.",
  "Fintech_Payment_activation_GNJM_joSQ": "Configura tu sistema con parámetros de {{brand_name}} por tu cuenta",
  "GRider_testing_Test_account_nnib": "Cuenta de prueba",
  "Wallet_App_Platform_This_account_TkJu": "Esta cuenta comprende el monto neto de transacciones catalogadas como potencialmente fraudulentas. Por favor, revisa tu correo electrónico para obtener más información sobre cómo enviar una solicitud de revisión.",
  "GRider_part2_Select_permission_lHal": "Seleccionar permisos",
  "Fintech_Payment_balance_SqOx_AyAJ": "Se refiere al saldo total a las 00:00 ({{time_zone}}) en el día especificado. Este monto es el mismo que el saldo al cierre del día anterior.",
  "GRider_part2_Here_is_JDga": "La información de liquidación de tu cuenta bancaria se muestra aquí. Si necesitas actualizar tu información, comunícate con tu administrador.",
  "GRider_part2_Creating_a_zflX": "Crear nuevo rol",
  "GRider_part2_Create_a_SGfq": "Crear un nuevo rol",
  "Fintech_Payment_activation_zjqY_gLat": "Selecciona primero un modelo de integración",
  "GRider_part1_Related_Transactions_UIBX": "Operaciones relacionadas:",
  "GRider_part2_Name_vbyE": "Nombre",
  "Fintech_Payment_activation_LjzQ_NEsx": "Notificaciones de reembolso",
  "Fintech_Payment_balance_tuMc_oNCn": "Comisiones",
  "Fintech_Payment_balance_DbDe_VdbR": "La longitud del periodo seleccionado no puede exceder el rango de tiempo a la izquierda",
  "GRider_part2_Date_hYez": "Fecha",
  "GRider_part1_Accounts_BFvS": "Cuenta",
  "Fintech_Payment_activation_JQpN_Tszu": "Confirma, por favor",
  "Fintech_Payment_activation_Ddpw_LpmL": "Clave solicitada",
  "Wallet_App_Platform_Thawing_hqVt": "Descongelar",
  "GRider_testing_Successful_payment_Yanu": "Pago completado",
  "Fintech_Payment_activation_HqZu_HXzt": "Realiza simulaciones en {{brand_name}} antes del lanzamiento oficial",
  "Fintech_Payment_activation_NqtQ_sjWJ": "Integración directa",
  "Wallet_App_Platform_Funds_from_uGZs": "El monto en esta cuenta se depositará automáticamente en tu cuenta bancaria.",
  "GRider_part2_Telephone_Number_vMII": "Número de teléfono",
  "GRider_part2_Withdrawal_refund_zKZp": "Reembolso del retiro",
  "GRider_part2_Enter_email_MOOA": "Ingresar correo electrónico",
  "GRider_part2_Edit_EsRp": "Editar",
  "Fintech_Payment_installment_bSZx_AgaB": "Administración de usuarios",
  "GRider_part1_Card_Type_QJgr": "Tipo de tarjeta",
  "Fintech_Payment_activation_tnxr_cHMD": "Integración a través de la plataforma",
  "Fintech_Payment_activation_FHYl_spTb": "Completa la prueba antes del lanzamiento oficial usando la cuenta de prueba provista por tu proveedor de servicios",
  "GRider_part1_Should_be_wIqf": "Aplicar",
  "Fintech_Payment_installment_CqfD_Ivza": "Comisiones",
  "GRider_part1_The_last_IpXv": "Últimos 3 meses",
  "Fintech_Payment_installment_heim_nBgM": "Establece el monto mínimo de meses sin intereses para cada periodo de pago.",
  "Wallet_App_Platform_Freeze_surplus_Woql": "Saldo congelado",
  "Fintech_Payment_activation_ZQbh_hGRy": "Luego de suscribirte, recibirás por correo electrónico la información sobre la disponibilidad de la plataforma, las interrupciones del servicio y el mantenimiento de rutina.",
  "Wallet_App_Platform_Freezing_lsVa": "Congelar",
  "GRider_part1_Order_No_OZVW": "Número de orden del comercio",
  "GRider_part1_Developers_UQKx": "Desarrolladores",
  "GRider_part1_Transactions_KxSe": "Transacciones",
  "Fintech_Payment_balance_mwQV_TfRo": "Ver detalles",
  "Fintech_Payment_installment_imFR_PvzU": "Entidades emisoras de tarjeta admitidas",
  "GRider_testing_Change_vqmJ": "Editar",
  "Fintech_Payment_installment_gveY_fVAe": "Configuración",
  "GRider_part1_Export_Transactions_RRhr": "Exportar informe de transacciones",
  "Fintech_Payment_installment_KSvh_imKX": "Método de pago",
  "GRider_part2_Enter_your_GLIm": "Ingresar número de teléfono",
  "Fintech_Payment_installment_RYcT_IbTU": "Marca de la tarjeta",
  "Fintech_Payment_activation_xoUl_LHiq": "Para completar la integración, envía los datos de {{brand_name}} a tu proveedor de servicios",
  "GRider_part1_Card_number_fuXk": "N.° de tarjeta",
  "Fintech_Payment_activation_JIXy_NPez": "Atrás",
  "Fintech_Payment_activation_isyA_IErj": "No",
  "GRider_part2_Editing_User_zQpH": "Editar información del usuario",
  "GRider_part2_Editing_role_Jtat": "Editar la información del rol",
  "Fintech_Payment_installment_jEkP_xnAG": "Configuración de meses sin intereses",
  "Fintech_Payment_installment_RysT_ofxZ": "Integra los meses sin intereses",
  "Fintech_Payment_balance_TDMh_lWOL": "Cantidad de movimientos",
  "GRider_part1_The_past_dJwH": "Semana pasada",
  "Fintech_Payment_installment_sNuL_KmUc": "Agregar cuenta nueva",
  "Fintech_Payment_balance_gUth_Obds": "Te permite revisar y configurar modelos de integración técnica, ver credenciales y usar herramientas de pruebas de simulación.",
  "Fintech_Payment_activation_WTVF_WSBd": "No pudo enviarse el código de verificación",
  "GRider_part1_Balance_tiVy": "Saldo",
  "Fintech_Payment_installment_bWei_KYLg": "Si activas esta opción, permitirás que tus clientes difieran sus pagos a meses sin intereses",
  "Fintech_Payment_activation_UsPA_vZEF": "Si eliges este modelo, no podrás cambiar a otro sin ayuda. ¿Estás seguro de que quieres continuar?",
  "GRider_part1_Log_RdHb": "Cerrar sesión",
  "GRider_part2_Activated_jMQn": "Activado",
  "GRider_part2_Discontinued_KKYu": "Desactivado",
  "Fintech_Payment_activation_ZUlh_QWGc": "Puedes administrar aquí la suscripción de correo electrónico. Luego de suscribirte, la información se enviará a tu cuenta de correo electrónico.",
  "GRider_part1_Request_for_Kprn": "Solicitar reembolso",
  "GRider_part2_Generation_Time_esBq": "Creado el",
  "GRider_part2_Creating_roles_tNvf": "Actualmente hay más de 100 roles. Debes eliminar uno antes de poder crear un nuevo usuario.",
  "Fintech_Payment_installment_WIri_etxW": "Selecciona el número máximo de meses sin intereses",
  "Fintech_Payment_activation_uJlg_hAxu": "Ingresa el código de verificación",
  "Fintech_Payment_activation_pYow_UUDd": "Solicitar credenciales",
  "GRider_part2_Creating_more_RwTS": "Actualmente hay más de 100 usuarios. Debes eliminar uno antes de poder crear un nuevo usuario.",
  "Fintech_Payment_installment_WvWU_CJNt": "Ilimitado",
  "Fintech_Payment_activation_JZLq_xGwA": "Con tecnología de {{brand_logo}}{{brand_logo}}",
  "Wallet_App_V2_Please_confirm_Pdiy": "Revisa tu configuración de red e inténtalo de nuevo más tarde.",
  "GRider_part1_Payment_Method_vqAa": "Método de pago",
  "GRider_testing_Set_Expired_XkNJ": "Establecer fecha de vencimiento",
  "GRider_part1_Send_JlWu": "Enviar",
  "GRider_part2_Finished_ebgM": "Completado",
  "GRider_part2_Discontinued_McUD": "Desactivar",
  "Fintech_Payment_installment_ICgQ_zIBK": "Entidades emisoras de tarjeta admitidas",
  "Fintech_Payment_balance_EbbT_fFaf": "Incluye todas las tarifas de servicio e impuestos (en caso de aplicar) cobrados por {{brand_name}}; las tarifas son cobradas el día del pago. Por ejemplo, si la norma de pago se establece como D+1, las tarifas de transacción y los impuestos incurridos el 1 de octubre se cobrarán el 2 de octubre.",
  "Fintech_Payment_activation_eAZQ_tnfg": "Usa esta opción para suscribirte rápidamente a toda la información disponible.",
  "GRider_part1_Full_refund_yDPa": "Reembolso total",
  "Fintech_Payment_installment_amhe_tddV": "Integra los meses sin intereses",
  "Fintech_Payment_activation_jSdX_KuOg": "Reenviar",
  "Fintech_Payment_activation_FvVc_nLWa": "Enviar código de verificación",
  "GRider_testing_Payment_failed_OuxY": "Error en el pago",
  "GRider_part1_Recently_updated_bpQC": "Última actualización el",
  "Fintech_Payment_balance_LmVM_SHeq": "Monto del movimiento",
  "GRider_part2_User_Details_RoWh": "Información del usuario",
  "Fintech_Payment_balance_bIBn_FVAb": "Ayer",
  "Fintech_Payment_onboarding_mhXP_NLLg": "Invalid format"
}