import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import { trackEvent } from "@/utils/omega";
import { PassportURL } from "@/utils/utils";
import { i18nTranslate } from "@didi/i18n-plugin";

// 通用错误提示
const ERROR_MESSAGE = {
  404: 'Invalid API.',
  13100: 'NOT LOGGED IN',
  NetworkBusy: 'Network Busy.',
}

// 错误类型
enum ERROR_TYPE {
  // 没有登陆
  NotLogged = 13100,
  // 没有商户
  NotMerchant = 13118,
}

type ResponseResult = {
  errno: number;
  data: unknown;
  errmsg: string;
  success: boolean;
}

const defaultSettings: AxiosRequestConfig = {

    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL: process.env.PUBLIC_API_ORIGIN || '',
    headers: {
        'Content-Type': "application/json; charset=utf-8",
    },
    
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 它只能用于 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 数组中最后一个函数必须返回一个字符串， 一个Buffer实例，ArrayBuffer，FormData，或 Stream
    // 你可以修改请求头。
    // transformRequest: [function (data, headers) {
    //     // 对发送的 data 进行任意转换处理
    //     return data;
    // }],
    
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [function (data) {
        // 对接收的 data 进行任意转换处理
        return data;
    }],
    // 跨域请求时是否需要使用凭证(cookies)
    withCredentials: true
      
}

// 通用请求头
const CommonHeaders = new Map();

export function setHeader(key: string, value: string | number) {
  CommonHeaders.set(key, value)
}

const request: AxiosInstance = axios.create(defaultSettings);

// 
request.interceptors.request.use();
// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么

    config.headers = config.headers || {}
    // @ts-ignore
    for(let [key, value] of CommonHeaders) {
      // console.log(key, value)
      config.headers[key] = value
    }

    /**
     * 完整请求结束后才上报请求埋点，若出现请求过程中网络抖动，请求可能无法上报
     * 因此在请求发起时也做一个埋点
     */
    trackEvent('tech_merchant_request_start','tech_merchant_request_start',{
      url: config.url,
    })
    
    return config;
  }, function (error) {
    // 对请求错误做些什么
    // return Promise.reject(error);
    return Promise.reject({ message: i18nTranslate('Wallet_App_V2_Please_confirm_Pdiy') } || error);
  });

// 添加响应拦截器
request.interceptors.response.use(function (response) {

    /**
     * 上报请求结果
     */
    try {
      let data = JSON.parse(response.data)
      trackEvent('tech_merchant_request_done','tech_merchant_request_done',{
        url: response?.config?.url,
        error_code: data?.errno,
        error_message: data?.errmsg,
        data
      })
    } catch (error) {
      /**
       * 上报请求错误
       */
      trackEvent('tech_merchant_request_error','tech_merchant_request_error',{
        url: response?.config?.url,
        error_code: error?.code,
        error_message: error?.message,
      })
    }

    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // console.log(response)
    // return response;
    console.log(response)
    if (response.data !== '') {
      return JSON.parse(response.data)
    } else {
      return ''
    }

  }, function (error) {
    /**
     * 上报请求错误
     */
    trackEvent('tech_merchant_request_error','tech_merchant_request_error',{
      url: error?.config?.url,
      error_code: error?.code,
      error_message: error?.message,
    })

    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // return Promise.reject(error);
    return Promise.reject({ message: i18nTranslate('Wallet_App_V2_Please_confirm_Pdiy') } || error);
});

// 本地记录用户登陆次数
const UserLoggedTimesKey = 'user_logged_times';

function handleResponse<T> (res: Promise<{errno: number; data: any; i18nMsg: string; errmsg: string }> , raw: boolean = false): Promise<T> {
  return res.then((ret) => {
    // if(ret.errno === 15000){
    //   return Promise.resolve([])
    // }
    if(ret.errno === 13117){
      return Promise.resolve([])
    }
    if(ret.errno !== 200) {
      // friendly notify
      const notifys: string = ret.i18nMsg || ret.errmsg || ERROR_MESSAGE.NetworkBusy;
      
      if(ret.errno === ERROR_TYPE.NotLogged || ret.errno === ERROR_TYPE.NotMerchant) {
        // 登陆次数
        const UserLoggedTimes = Number(localStorage.getItem(UserLoggedTimesKey)) || 0;
        // 登陆次数+1
        localStorage.setItem(UserLoggedTimesKey, String(UserLoggedTimes + 1));
        // 新用户首次访问直接跳转登陆页
        setTimeout(() => window.location.href = PassportURL(), UserLoggedTimes > 0 ? 1000 * 2 : 0);
        if(UserLoggedTimes === 0) {
          return Promise.reject({ message: notifys });
        }
      }
      if(ret.errno !==13100){
         // toast error message
        message.error({ content: notifys });
      }
      return Promise.reject({ message: notifys });
    }
    if(raw) {
      return ret;
    }
    return Promise.resolve(ret.data || {})
  }, error => {
    message.error({ content: error.message || ERROR_MESSAGE.NetworkBusy });
    return Promise.reject(error)
  })
}

type RequestParams = {
  [key: string]: any
}

type RequestConfig = AxiosRequestConfig & { raw? : boolean }



/**
 * Post
 * @author lawrencehou
 * @template T 
 * @param url 
 * @param params 
 * @param opts 
 * @returns t 
 */
export function post<T>(url: string, params: RequestParams, opts?: RequestConfig ): Promise<T> {
  return handleResponse<T>(request.post(url, params, opts), opts && opts.raw)
}

/**
 * Get
 * @author lawrencehou
 * @template T 
 * @param url 
 * @param opts 
 * @returns t 
 */
export function get<T>(url: string, opts?: RequestConfig): Promise<T> {
  return handleResponse<T>(request.get(url, opts), opts && opts.raw)
}


/**
 * Delete
 * @author lawrencehou
 * @template T 
 * @param url 
 * @param opts 
 * @returns t 
 */
export function del<T>(url: string, opts?: RequestConfig ): Promise<T> {
  return handleResponse<T>(request.delete(url, opts), opts && opts.raw)
}

/**
 * Put
 * @author lawrencehou
 * @template T 
 * @param url 
 * @param params 
 * @param opts 
 * @returns t 
 */
export function put<T>(url: string, params: RequestParams, opts?: RequestConfig ): Promise<T> {
  return handleResponse<T>(request.put(url, params, opts), opts && opts.raw)
}



