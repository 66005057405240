module.exports = {
  "GRider_part1_Enter_NzrE": "Insira",
  "GRider_part1_Available_surplus_qJnT": "Saldo disponível",
  "Fintech_Payment_activation_IzyA_cilV": "Etapa {{num}}",
  "Fintech_Payment_homepage__XSnB": "Visualize dados de transações e tendências de volume de transações",
  "GRider_part1_Daily_newspaper_LEkt": "Relatórios diários",
  "Fintech_Payment_installment_YXNp_PFht": "Para ativar os pagamentos parcelados, tenha em mãos os seus dados de transação anteriores e entre em contato com nossos Desenvolvedores Comerciais ou envie um e-mail para {{email_address}}",
  "GRider_part2_Beneficiaries_ofYu": "Beneficiário",
  "GRider_part1_Refusal_to_pkkj": "Relatórios de estorno",
  "Fintech_Payment_balance_GMTI_Quqq": "Saldo Final = Saldo Inicial + (Transações − Taxas − Resgates)",
  "GRider_part2_Tip_The_SSvk": "Observação: o endereço de e-mail e o número do telefone não poderão ser editados após a criação do usuário",
  "GRider_part1_Card_issuing_UMOj": "Emissor",
  "Fintech_Payment_activation_Gwim_BefB": "Antes de integrar, primeiro é necessário solicitar credenciais de seu prestador de serviço. Selecione seu prestador de serviço",
  "GRider_part1_Yesterday_Qqwf": "Ontem",
  "Fintech_Payment_activation_GjlD_wkpD": "Faça uma chamada você mesmo para a API da marca {{brand_name}}",
  "Fintech_Payment_installment_hTIQ_Vrgf": "Conta",
  "Fintech_Payment_balance_eQKY_heQN": "Ferramentas do desenvolvedor",
  "Fintech_Payment_installment_qiLa_XtZT": "Concluído com sucesso",
  "GRider_part1_Today_YHmE": "Hoje",
  "GRider_testing_Payment_VpTU": "Pagamento",
  "Fintech_Payment_homepage__RmdB": "Relatórios",
  "Wallet_App_Platform_Freezing_ppNk": "Bloqueada",
  "Fintech_Payment_installment_PFLR_ompu": "Aceitar pagamentos parcelados",
  "Fintech_Payment_activation_vUKr_wcKq": "Credenciais para {{platform_name}}",
  "Fintech_Payment_installment_hSSg_zUNZ": "Os juros de parcelamento podem variar de acordo com o número de parcelas ou os meses.",
  "Fintech_Payment_balance_elMb_vZFR": "Como ler os dados",
  "Fintech_Payment_balance_KpPH_aCTM": "Sua conta",
  "Fintech_Payment_balance_jhxV_SUuT": "Acesso rápido",
  "GRider_testing_Action_OCOw": "Ações",
  "Fintech_Payment_homepage__NBZW": "Olá!",
  "GRider_part1_Sent_successfully_kjRA": "Enviado com sucesso",
  "GRider_part1_Description_wbwg": "Descrição",
  "GRider_part2_Download_WoXh": "Baixar",
  "GRider_part2_E_mail_jpRL": "Endereço de e-mail",
  "GRider_part2_Associated_Billing_YMdm": "Nº do demonstrativo associado",
  "Fintech_Payment_activation_LhwA_UQij": "Solicitar",
  "GRider_testing_The_test_mTcU": "Você está usando um perfil de teste. Você pode simular os resultados do teste alterando as regras em cada cenário.",
  "GRider_part1_Order_No_Ycoc": "Número de referência",
  "Fintech_Payment_activation_YFdK_GDwn": "Para mais informações, clique aqui",
  "GRider_part1_Total_balance_DpEi": "Saldo total",
  "Fintech_Payment_activation_DmlQ_MEHJ": "Parece que você não fez a integração ainda",
  "GRider_part1_Statement_of_EvSW": "Relatórios de liquidação",
  "GRider_part1_Reset_Password_qUom": "Redefinir senha",
  "Fintech_Payment_activation_Jgbl_pUBk": "Com apoio de",
  "GRider_part2_View_and_IngR": "Exibir e pesquisar",
  "GRider_testing_Rules_wFPn": "Regra",
  "Fintech_Payment_activation_ewlq_IoNo": "Código de verificação enviado",
  "GRider_part2_Waiting_geZn": "Pendente",
  "GRider_part2_Name_of_MryB": "Nome do banco",
  "GRider_part2_callback_address_ktAH": "URL de retorno de chamada",
  "Fintech_Payment_installment_KJiW_YtRu": "O número de parcelas é mostrado quando o valor do pedido é superior a este valor",
  "GRider_part1_Card_Expired_PDgL": "Data de validade",
  "GRider_part2_Related_withdrawals_waNU": "Saques relacionados",
  "Fintech_Payment_installment_rJDb_YgOl": "Prazo de parcelamento",
  "GRider_part2_File_size_YxTo": "Tamanho do arquivo",
  "GRider_part2_Mid_ylNK": "MID",
  "GRider_part3_Successfully_replicated_waxb": "Copiado",
  "GRider_part2_You_can_JwSD": "Você pode configurar a URL de retorno de chamada para receber notificações",
  "GRider_part3_Incorrect_mobile_nRlv": "Formato de número de telefone inválido",
  "Fintech_Payment_activation_jxCH_yKtk": "Teste simulado",
  "GRider_part2_Option_MwIV": "Selecionado",
  "GRider_part2_Platform_Public_awvm": "Chave pública da plataforma",
  "GRider_part1_Cards_kUwm": "País emissor",
  "Fintech_Payment_homepage__fqqN": "Recursos do produto",
  "GRider_part2_Update_Time_gjBJ": "Atualizado em",
  "GRider_part2_Bank_Account_loWF": "Número da conta",
  "Fintech_Payment_installment_ymsu_wzNC": "Financeiro",
  "Fintech_Payment_activation_ihwN_LvcJ": "Avançar",
  "Fintech_Payment_installment_nABX_fEaU": "Entre em contato com nossos Desenvolvedores Comerciais e assine o aditamento contratual",
  "GRider_part2_You_can_hYnM": "Você pode ver e obter suas chaves de produção aqui",
  "GRider_part1_Home_Page_uezP": "Início",
  "GRider_part2_Name_of_oyxC": "Nome do arquivo",
  "GRider_part2_Production_keys_NWqC": "Chaves de produção",
  "GRider_part2_Save_Limz": "Salvar",
  "Fintech_Payment_installment_llHq_rMAE": "Início",
  "GRider_part2_Appid_BsOA": "AppID",
  "GRider_part2_Enter_a_zelI": "Insira o nome",
  "GRider_part2_Create_a_jFJI": "Criar usuário",
  "Fintech_Payment_installment_hjTq_dlnR": "Planos de pagamento parcelado",
  "Fintech_Payment_balance_NbxK_SaKX": "Permite visualizar informações sobre a conta bancária usada para pagamento",
  "GRider_part2_Contains_all_XBsh": "Inclui todas as transações criadas por dia",
  "Fintech_Payment_installment_DVlZ_kYzm": "O número de parcelas que você selecionar também incluirá todos os planos com um número menor de parcelas. Por exemplo, se você selecionar 6 parcelas, serão incluídos todos os planos de parcelamento de 2 a 6 prestações.",
  "Fintech_Payment_activation_Ifcd_qoWL": "Selecione este modelo se você trabalhou com uma agência para criar seu site",
  "Fintech_Payment_installment_UPAc_xQXH": "Valor do pagamento máximo",
  "Fintech_Payment_installment_cGuN_hnbl": "Extrato",
  "GRider_part2_Reason_hMEF": "Motivo",
  "GRider_part1_Refund_amount_DAYO": "Valor do reembolso",
  "GRider_testing_Overdue_period_XwCh": "Horário de expiração",
  "Fintech_Payment_activation_ozQV_GveB": "Confirmar",
  "GRider_part2_Is_a_Dnrr": "Tem certeza de que deseja confirmar este reembolso?",
  "GRider_part2_Character_Details_FNou": "Detalhes da função",
  "GRider_part1_Between_the_eAiK": "Criado em",
  "Fintech_Payment_installment_DnTv_Xkvt": "O {{brand_name}} agora aceita pagamento parcelado com cartão de crédito. Este recurso pode ajudar a aumentar sua taxa de conversão de pedidos e seu volume de transações.",
  "Fintech_Payment_balance_hSpF_MbAB": "Hoje",
  "Fintech_Payment_balance_nyOS_vxXE": "Clique em cada módulo para concluir as configurações ou visualizar as informações.",
  "Fintech_Payment_balance_CuLR_ULHc": "Saldo final",
  "Fintech_Payment_installment_ZVzi_GWHv": "Banco emissor",
  "GRider_part2_Callback_address_mWOg": "Configuração da URL de retorno de chamada",
  "GRider_part2_Store_Private_wkmJ": "Chave privada do comerciante",
  "GRider_part1_We_will_UNRe": "Enviaremos seu relatório para o e-mail:",
  "GRider_part2_Cost_vEwI": "Taxas",
  "GRider_part2_Withdrawal_Details_dHNg": "Detalhes do saque",
  "Fintech_Payment_installment_Vaha_KRAt": "Ative manualmente o pagamento parcelado na plataforma de comerciante",
  "GRider_part1_The_past_mhPm": "Mês passado",
  "Fintech_Payment_balance_NFvu_eglf": "Saldo inicial",
  "GRider_part1_Payment_Details_DAiw": "Detalhes do pagamento",
  "Fintech_Payment_installment_AVlb_xZKm": "{{specific_number}} meses",
  "GRider_part2_Refunded_asmQ": "Reembolsado",
  "GRider_part1_Type_LsnR": "Tipo",
  "Fintech_Payment_activation_bMnh_ctqQ": "Selecione esse modelo se você mesmo criou seu site e sistemas",
  "Fintech_Payment_homepage__ypPH": "Desenvolvedores",
  "GRider_part2_The_most_RGVh": "Hora do último acesso",
  "GRider_part2_Click_the_LDVM": "Clique no botão à direita para enviar por e-mail",
  "GRider_part1_Order_No_YaNe": "Nº de ref/solicit. do comerciante",
  "Fintech_Payment_installment_gQRp_NZeC": "Você não precisa fazer nenhuma alteração. Nós vamos configurar tudo, incluindo os planos de parcelamento e o valor de pagamento mínimo para cada prazo de parcelamento.",
  "GRider_part3_Refund_has_KQgL": "Reembolso solicitado",
  "Fintech_Payment_homepage__KZpP": "Esta é a Plataforma do Comerciante {{brand_name}}. Aqui você encontra os dados e os recursos necessários para as operações diárias.",
  "Fintech_Payment_balance_IpxB_ywzS": "Inclui quatro tipos de transações: pagamento, reembolso, estorno e reversão de estorno. Destes, os valores de pagamento e reversão de estorno são positivos e os valores de reembolso e estorno são negativos.",
  "Fintech_Payment_activation_wjcD_GlZe": "Após a inscrição, você receberá uma notificação por e-mail sobre reembolsos que resultem de transações contestadas pelos clientes",
  "GRider_part1_Amount_paid_WEun": "Valor do pagamento",
  "Fintech_Payment_installment_FgNU_zmkD": "Ativar o pagamento parcelado em três etapas",
  "Wallet_App_Platform_The_money_vDON": "O valor nesta conta é o valor líquido de transações e reembolsos descontadas quaisquer taxas de serviço relevantes. Esse valor será transferido para sua conta assim que as condições de liquidação forem atendidas.",
  "GRider_part1_Balance_to_QsRx": "Saldo pendente",
  "GRider_testing_Set_Rules_zJNI": "Defina a regra",
  "Fintech_Payment_activation_qLYh_PPxk": "Selecione seu prestador",
  "Fintech_Payment_installment_qogy_NcYo": "Ativar o pagamento parcelado em três etapas",
  "GRider_part3_URL_format_iCKD": "Formato de URL inválido",
  "GRider_part2_Net_transactions_gAaN": "Valor líquido da transação",
  "Fintech_Payment_balance_yezl_qhlK": "Atualizar",
  "Fintech_Payment_installment_IPpd_Svmd": "Taxas",
  "GRider_part1_Today_s_hdrF": "Tendências de hoje",
  "Fintech_Payment_activation_nxJQ_NgNJ": "Disponibilidade de plataforma e manutenção",
  "GRider_part2_Action_cmsL": "Ações",
  "GRider_part1_Export_YZAn": "Exportar",
  "Fintech_Payment_installment_YUrU_qPmd": "Nosso recurso de pagamento parcelado é compatível com as bandeiras de cartão mais conhecidas. Confira se seu cartão está incluído.",
  "Fintech_Payment_installment_riIt_DDvt": "Ilimitado",
  "GRider_part2_Send_to_AtRh": "Enviar por e-mail",
  "Fintech_Payment_activation_dgcg_YmoP": "Selecione seu prestador de serviço",
  "Fintech_Payment_installment_rOTK_eywP": "Selecione os planos de pagamento parcelado que você vai aceitar",
  "GRider_testing_Simulation_test_vGQn": "Teste simulado",
  "Fintech_Payment_activation_uczM_UXBr": "Selecione este modelo",
  "GRider_part3_Successfully_saved_lalL": "Salvo",
  "GRider_part3_Refund_failed_VnYA": "Não foi possível reembolsar. Tente novamente mais tarde",
  "GRider_testing_Scenarios_iISM": "Cenário",
  "GRider_part1_Confirmation_AyRV": "Confirmar",
  "Fintech_Payment_balance_DtSD_XkwH": "Valor da transação",
  "GRider_part2_Total_balance_YZYv": "Saldo total",
  "Fintech_Payment_installment_SQJZ_aEpL": "Defina as configurações de pagamentos parcelados do cliente aqui",
  "Fintech_Payment_installment_kMba_amRL": "Valor do pagamento mínimo",
  "GRider_part1_Refundable_sZhr": "Valor reembolsável",
  "GRider_part1_Amount_vtzS": "Valor",
  "Fintech_Payment_activation_obwW_aWIM": "Inscrever em todos",
  "Fintech_Payment_installment_mEXH_WggE": "Gerenciamento de função",
  "Fintech_Payment_balance_GbVq_mdCb": "Refere-se ao valor da transferência após o pagamento. Se um resgate não for bem sucedido, o valor será reembolsado imediatamente e o valor do resgate será negativo.",
  "Fintech_Payment_activation_kfLV_lJHI": "Token solicitado",
  "Fintech_Payment_installment_dddG_AtTw": "Número de parcelas",
  "GRider_part2_Withdrawal_ID_SrUS": "ID do saque",
  "GRider_part2_All_transactions_zvvZ": "Inclui todas as transações de liquidação",
  "GRider_part2_Cash_Amount_LJKk": "Saques",
  "GRider_testing_Select_the_zelH": "Selecione o cenário",
  "GRider_part2_Unable_to_yJGm": "O perfil do usuário não poderá ser recuperado depois que for excluído",
  "GRider_part2_Resumed_after_hqrX": "Reenviar em {{time}}s",
  "GRider_part1_Clear_pScF": "Limpar",
  "GRider_part2_Delete_the_lFFi": "Excluir este usuário?",
  "Fintech_Payment_balance_hfIW_kqDR": "Resgates",
  "Fintech_Payment_installment_YUyv_ddsJ": "Pagar com saldo",
  "Fintech_Payment_activation_wfOI_wbgn": "Informe o código de verificação",
  "GRider_part2_Total_transaction_vOfv": "Valor total da transação",
  "GRider_testing_Test_account_jNcp": "Perfil de teste",
  "GRider_part1_Cancel_VJqj": "Cancelado",
  "GRider_part2_Activation_oBkK": "Ativar",
  "GRider_part1_Transaction_Details_QlYj": "Detalhes da transação",
  "Fintech_Payment_installment_iwhg_GXAN": "Inscrições",
  "Fintech_Payment_installment_mBYW_wReM": "Pagar parcelado",
  "Fintech_Payment_activation_FXqF_QMDQ": "Integração por agência",
  "Fintech_Payment_homepage__ckdF": "Obtenha relatórios detalhados de transações, pagamentos e estornos",
  "GRider_part2_Role_kDjH": "Funções",
  "GRider_part2_Copy_mepW": "Copiar",
  "GRider_part1_User_management_XlPK": "Gerenciar usuários",
  "GRider_part1_Role_management_BGrU": "Gerenciar funções",
  "Fintech_Payment_activation_NHCc_pjPW": "Inscrições",
  "GRider_part3_The_key_BlJh": "A chave foi enviada para o e-mail do administrador ({{email}})",
  "GRider_testing_Key_and_pUWf": "Chave e retorno de chamada",
  "Fintech_Payment_activation_WPSw_nvwn": "A API da marca {{brand_name}} receberá uma chamada de seu prestador de serviço",
  "GRider_part3_Account_Details_Rhrj": "Sua conta",
  "Fintech_Payment_installment_nZUF_eajs": "Seus clientes podem pagar parcelado",
  "GRider_part1_Status_QkjC": "Status",
  "GRider_part2_Delete_Mdzk": "Deletar",
  "Fintech_Payment_balance_OMHo_KuHt": "Selecione o período de comparação",
  "GRider_part3_Tip_Modify_ZuaO": "Lembrete: ainda é possível editar após salvar",
  "GRider_part2_Did_you_zYqT": "Excluir esta função?",
  "GRider_part2_Create_a_cXhs": "Criar novo usuário",
  "GRider_part1_Withdrawal_hMai": "Saques",
  "GRider_part3_Contains_daily_mqQx": "Inclui os dados de estorno gerados e atualizados diariamente",
  "Fintech_Payment_balance_JuIe_SCxH": "a partir de {{specific_hour}}",
  "GRider_part1_The_past_KXKB": "Ano passado",
  "Fintech_Payment_homepage__mrUl": "Acesse a documentação completa para desenvolvedores e a funcionalidade de teste simulado",
  "Fintech_Payment_activation_ZSUb_fBQj": "Credenciais",
  "Fintech_Payment_activation_kngw_rjJO": "Integração direta",
  "Fintech_Payment_installment_HVdy_lDaD": "Transações",
  "Fintech_Payment_homepage__iUXv": "Painel de dados",
  "GRider_part3_Cancel_rVRs": "Cancelar",
  "Fintech_Payment_activation_tNUu_MyKS": "Isso ajudará a manter seu perfil seguro. Um código de verificação foi enviado para {{email_address}}",
  "Fintech_Payment_activation_GNJM_joSQ": "Configure seu sistema você mesmo usando parâmetros da marca {{brand_name}}",
  "Fintech_Payment_onboarding_mhXP_NLLg": "Formato inválido",
  "GRider_testing_Test_account_nnib": "Perfil de teste",
  "Wallet_App_Platform_This_account_TkJu": "Essa conta é o valor líquido das operações sinalizadas para potenciais riscos. Verifique seu e-mail para obter mais informações sobre como enviar uma contestação.",
  "GRider_part2_Select_permission_lHal": "Selecionar permissões",
  "Fintech_Payment_balance_SqOx_AyAJ": "Refere-se ao saldo total a partir das 00:00 ({{time_zone}}) do dia especificado. Esse valor é o mesmo que o saldo final do dia anterior.",
  "GRider_part2_Here_is_JDga": "As informações da liquidação da conta bancária são exibidas aqui. Se você precisar atualizar suas informações, entre em contato com o gerente da conta.",
  "GRider_part2_Creating_a_zflX": "Criar uma função",
  "GRider_part2_Create_a_SGfq": "Criar uma nova função",
  "Fintech_Payment_activation_zjqY_gLat": "Selecione um modelo de integração primeiro",
  "GRider_part1_Related_Transactions_UIBX": "Transações relacionadas",
  "GRider_part2_Name_vbyE": "Nome",
  "Fintech_Payment_activation_LjzQ_NEsx": "Notificações de reembolso",
  "Fintech_Payment_balance_tuMc_oNCn": "Taxas",
  "Fintech_Payment_balance_DbDe_VdbR": "O período selecionado não pode exceder o intervalo de tempo à esquerda",
  "GRider_part2_Date_hYez": "Data",
  "GRider_part1_Accounts_BFvS": "Perfil",
  "Fintech_Payment_activation_JQpN_Tszu": "Confirme",
  "Fintech_Payment_activation_Ddpw_LpmL": "Chave solicitada",
  "Wallet_App_Platform_Thawing_hqVt": "Desbloquear",
  "GRider_testing_Successful_payment_Yanu": "Pagamento concluído",
  "Fintech_Payment_activation_HqZu_HXzt": "Faça testes simulados na marca {{brand_name}} antes do lançamento oficial",
  "Fintech_Payment_activation_NqtQ_sjWJ": "Integração direta",
  "Wallet_App_Platform_Funds_from_uGZs": "O valor desta conta será depositado automaticamente em sua conta bancária.",
  "GRider_part2_Telephone_Number_vMII": "Nº de celular",
  "GRider_part2_Withdrawal_refund_zKZp": "Reembolso do saque",
  "GRider_part2_Enter_email_MOOA": "Insira o e-mail",
  "GRider_part2_Edit_EsRp": "Editar",
  "Fintech_Payment_installment_bSZx_AgaB": "Gerenciamento de usuário",
  "GRider_part1_Card_Type_QJgr": "Tipo de cartão",
  "Fintech_Payment_activation_tnxr_cHMD": "Integração pela plataforma",
  "Fintech_Payment_activation_FHYl_spTb": "Realize os testes antes do lançamento oficial usando o perfil de teste disponibilizado por seu prestador de serviço",
  "GRider_part1_Should_be_wIqf": "Aplicar",
  "Fintech_Payment_installment_CqfD_Ivza": "Taxas",
  "GRider_part1_The_last_IpXv": "Últimos 3 meses",
  "Fintech_Payment_installment_heim_nBgM": "Defina o valor mínimo da parcela para cada prestação",
  "Wallet_App_Platform_Freeze_surplus_Woql": "Saldo bloqueado",
  "Fintech_Payment_activation_ZQbh_hGRy": "Após a inscrição, as informações sobre a disponibilidade da plataforma, interrupções do sistema e manutenções de rotina serão enviadas para seu e-mail",
  "Wallet_App_Platform_Freezing_lsVa": "Bloquear",
  "GRider_part1_Order_No_OZVW": "Nº da solicitação do comerciante",
  "GRider_part1_Developers_UQKx": "Desenvolvedores",
  "GRider_part1_Transactions_KxSe": "Transações",
  "Fintech_Payment_balance_mwQV_TfRo": "Ver detalhes",
  "Fintech_Payment_installment_imFR_PvzU": "Bandeiras de cartões aceitas",
  "GRider_testing_Change_vqmJ": "Editar",
  "Fintech_Payment_installment_gveY_fVAe": "Configurações",
  "GRider_part1_Export_Transactions_RRhr": "Exportar relatório de transação",
  "Fintech_Payment_installment_KSvh_imKX": "Método de pagamento",
  "GRider_part2_Enter_your_GLIm": "Insira o número do celular",
  "Fintech_Payment_installment_RYcT_IbTU": "Bandeira do cartão",
  "Fintech_Payment_activation_xoUl_LHiq": "Realize a integração enviando credenciais da marca {{brand_name}} para seu prestador de serviço",
  "GRider_part1_Card_number_fuXk": "Nº do cartão",
  "Fintech_Payment_activation_JIXy_NPez": "Voltar",
  "Fintech_Payment_activation_isyA_IErj": "Cancelar",
  "GRider_part2_Editing_User_zQpH": "Editar informações do usuário",
  "GRider_part2_Editing_role_Jtat": "Editar informações da função",
  "Fintech_Payment_installment_jEkP_xnAG": "Configurações de pagamento parcelado",
  "Fintech_Payment_installment_RysT_ofxZ": "Integrar pagamentos parcelados",
  "Fintech_Payment_balance_TDMh_lWOL": "Número de transações",
  "GRider_part1_The_past_dJwH": "Semana passada",
  "Fintech_Payment_installment_sNuL_KmUc": "Adicionar nova conta",
  "Fintech_Payment_balance_gUth_Obds": "Permite verificar e definir modelos de integração técnica, visualizar credenciais e usar ferramentas de teste simulado.",
  "Fintech_Payment_activation_WTVF_WSBd": "Falha no envio do código de verificação",
  "GRider_part1_Balance_tiVy": "Saldo",
  "Fintech_Payment_installment_bWei_KYLg": "Ao ativar essa opção, você vai permitir que os clientes paguem parcelado",
  "Fintech_Payment_activation_UsPA_vZEF": "Se selecionar este modelo, você não poderá alterar para outro. Tem certeza de que deseja continuar?",
  "GRider_part1_Log_RdHb": "Sair",
  "GRider_part2_Activated_jMQn": "Ativado",
  "GRider_part2_Discontinued_KKYu": "Desativado",
  "Fintech_Payment_activation_ZUlh_QWGc": "Você pode gerenciar a inscrição por e-mail aqui. Após a inscrição, as informações serão enviadas para seu endereço de e-mail",
  "GRider_part1_Request_for_Kprn": "Solicitação de reembolso",
  "GRider_part2_Generation_Time_esBq": "Gerado em",
  "GRider_part2_Creating_roles_tNvf": "No momento, há mais de 100 funções. Você precisará excluir um para criar outra.",
  "Fintech_Payment_installment_WIri_etxW": "Selecione a quantidade máxima de parcelas",
  "Fintech_Payment_activation_uJlg_hAxu": "Informe o código de verificação",
  "Fintech_Payment_activation_pYow_UUDd": "Solicite credenciais",
  "GRider_part2_Creating_more_RwTS": "No momento, há mais de 100 usuários. Você precisará excluir um para criar outro.",
  "Fintech_Payment_installment_WvWU_CJNt": "Ilimitado",
  "Fintech_Payment_activation_JZLq_xGwA": "Suportado por {{brand_logo}}{{brand_logo}}",
  "Wallet_App_V2_Please_confirm_Pdiy": "Por favor, verifique suas configurações de rede e tente novamente mais tarde.",
  "GRider_part1_Payment_Method_vqAa": "Método de pagamento",
  "GRider_testing_Set_Expired_XkNJ": "Defina o horário de expiração",
  "GRider_part1_Send_JlWu": "Enviar",
  "GRider_part2_Finished_ebgM": "Concluído",
  "GRider_part2_Discontinued_McUD": "Desativar",
  "Fintech_Payment_installment_ICgQ_zIBK": "Bandeiras de cartões aceitas",
  "Fintech_Payment_balance_EbbT_fFaf": "Inclui todas as taxas de serviços e impostos (se houver) cobrados pela {{brand_name}}; as taxas são cobradas no dia do pagamento. Por exemplo, se a regra de pagamento for definida como D+1, as taxas de transação e os impostos incorridos em 1º de outubro serão cobrados no dia 2 de outubro.",
  "Fintech_Payment_activation_eAZQ_tnfg": "Use essa opção para se inscrever rapidamente para todas as informações disponíveis",
  "GRider_part1_Full_refund_yDPa": "Reembolso total",
  "Fintech_Payment_installment_amhe_tddV": "Integrar pagamentos parcelados",
  "Fintech_Payment_activation_jSdX_KuOg": "Reenviar",
  "Fintech_Payment_activation_FvVc_nLWa": "Envie o código de verificação",
  "GRider_testing_Payment_failed_OuxY": "Erro no pagamento",
  "GRider_part1_Recently_updated_bpQC": "Última atualização em",
  "Fintech_Payment_balance_LmVM_SHeq": "Valor da transação",
  "GRider_part2_User_Details_RoWh": "Detalhes do usuário",
  "Fintech_Payment_balance_bIBn_FVAb": "Ontem"
}